import { render, staticRenderFns } from "./timer_vdf_comp.vue?vue&type=template&id=d07c617a&scoped=true"
import script from "./timer_vdf_comp.vue?vue&type=script&lang=js"
export * from "./timer_vdf_comp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d07c617a",
  null
  
)

export default component.exports