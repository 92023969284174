<!-- 支付链接 -->
<template>
  <div class="">
    <!-- 倒计时 -->
    <TimerComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
    <!--支付链接-->
    <div class="pay-alip">
      <!--支付宝-->
      <div v-if="orderInfo.payType=='alipay'">
        <a :href="orderInfo.payUrl" class="btn btn-alip" style="width: 70%;">立即支付</a>
      </div>
      <!--微信-->
      <div v-if="orderInfo.payType=='weixin'">
        <a :href="orderInfo.payUrl" class="btn btn-weix" style="width: 70%;">立即支付</a>
      </div>
    </div>
    <!-- 警告事项 -->
    <div class="pannel-simp attention">
      <p class=""><b>注意：</b><span style="color: red;">请勿超时支付，否则</span>无法追回!!!</p>
      <p class=""><b>注意：</b><span style="color: red;">请勿修改金额，否则</span>无法追回!!!</p>
      <p class=""><b>注意：</b><span style="color: red;">次日前未查,</span>无法追回!!!</p>
      <p class=""><b>注意：</b>支付成功确认时间<span style="color: red;">5-10分钟</span>,付款后<span style="color: red;">超过10分钟</span>还未到账,请联系客服查单补单!</p>
    </div>
  </div>
</template>

<script>
import TimerComp from "@/views/comp/timer_comp";
export default {
  components: {
    TimerComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
