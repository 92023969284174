<!-- 旗舰话费 -->
<template>
  <div>
    <!-- 复制区域 -->
    <div class="pannel" style="">
      <table class="details" style="">
        <tr>
          <td>手机号码：</td>
          <td>
            <span v-if="copyPhoneIf" style="color: green;"><b>{{orderInfo.phone}}</b></span>
            <span v-else style="color: blue;"><b>{{orderInfo.phone}}</b></span>
            <button v-on:click="copyPhone" class="btn copy">复制号码</button>
          </td>
        </tr>
        <tr>
          <td>金额</td>
          <td>
            <span v-if="cptyAmountIf" style="color: green;"><b>{{orderInfo.amount}}</b></span>
            <span v-else style="color: red;"><b>{{orderInfo.amount}}</b></span>
            <button @click="copyAmount" class="btn copy">复制金额</button>
          </td>
        </tr>
      </table>
    </div>

    <!-- 倒计时 -->
    <TimerComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />

    <!--唤醒按钮-->
    <div class="pay-alip" v-if="orderInfo.payType=='douyin'">
      <!--支付宝唤醒按钮-->
      <div v-if="orderInfo.payType=='douyin'">
        <a href="snssdk1128://" class="btn btn-alip" style="width: 70%;">立即支付</a>
      </div>
    </div>

    <!-- 付款流程 -->
    <div class="pannel">
      <div class="pay-flow" v-if="orderInfo.payType=='weixin'">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、打开微信APP</p>
        <p class="no">2、搜索“腾讯手机充值”小程序并进入；</p>
        <p class="no">3、填上相对应的：手机号码、金额；</p>
        <p class="no">4、然后完成支付</p>
      </div>
      <div class="pay-flow" v-if="orderInfo.payType=='alipay'">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、打开支付宝APP</p>
        <p class="no">2、搜索“手机充值”小程序并进入；</p>
        <p class="no">3、填上相对应的：手机号码、金额；</p>
        <p class="no">4、然后完成支付</p>
      </div>
      <div class="pay-flow" v-if="orderInfo.payType=='douyin'">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、手动打开抖音APP</p>
        <p class="no">2、我-右上角“≡”-我的钱包手机充值功能</p>
        <p class="no">3、长按手机号码输入框,粘贴充值号码</p>
        <p class="no">4、选择或输入对应的金额</p>
        <p class="no">5、然后完成支付</p>
      </div>
    </div>

    <!--演示视频-->
    <div class="pannel-simp title" v-if="orderInfo.payType=='douyin'">
      <div class="">
        <button class="btn video" @click="showVideo=!showVideo">显示支付流程视频</button>
      </div>
      <div style="text-align:center;" v-if="orderInfo.payType=='douyin' && showVideo">
        <img v-if="orderInfo.payType=='douyin'" src="/imgs/douyin01.gif" />
      </div>
    </div>

    <!-- 警告事项 -->
    <div class="pannel-simp attention">
      <p class="">注意：<span style="color: red;">超时支付,修改金额,修改支付方式,重复支付,</span>无法追回!!!</p>
      <p class="">注意：<span style="color: red;">次日前未查,</span>无法追回!!!</p>
      <p class="">注意：支付成功确认时间<span style="color: red;">5-10分钟</span>,付款后<span style="color: red;">超过10分钟</span>还未到账,请联系客服查单补单!</p>
    </div>

  </div>
</template>

<script>
import TimerComp from "@/views/comp/timer_comp";

export default {
  components: {
    TimerComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    alipInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 复制相关
      copyPhoneIf: false,
      cptyAmountIf: false,
      //
      showVideo: false, //显示视频
    };
  },
  computed: {},
  created() {},
  methods: {
    // 复制手机
    copyPhone: function () {
      this.$parent.$parent.copySome(
        this.orderInfo.phone,
        1,
        () => {
          this.copyPhoneIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.$parent.copyErr();
        }
      );
    },
    // 复制金额
    copyAmount: function () {
      this.$parent.$parent.copySome(
        this.orderInfo.amount,
        2,
        () => {
          this.cptyAmountIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.$parent.copyErr();
        }
      );
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
