<!-- changelly -->
<template>
  <div class="">
    <!--提示-->
    <div class="title">
      <div style="margin: 1.55em 0;">
        <h3>Quét mã để chuyển tiền</h3>
      </div>
    </div>
    <!-- 详细信息 -->
    <div class="title" style="">
      <div class="bankcard-owner">
        <b>{{orderInfo.ownerName}}</b>
      </div>
      <div class="bankcard-account">
        {{orderInfo.accountNumber}}
      </div>
    </div>
    <!--金额-->
    <div class="title">
      <div class="bankcard-amount">
        <b>{{orderInfo.amount|formatCurrency}}</b>
      </div>
    </div>
    <div class="title" style="">
      <div v-if="qcode">
        <img src="/imgs/bc/ACB2.png" style="margin: 0 auto;width: 60%;" />
        <img :src="qcode" style="margin: 0 auto;width: 60%;" ref="qrImg" download="aaa.jpg" />
      </div>
    </div>
    <!--btn-->
    <div>
      <div class="pay-alip">
        <button class="btn bankcard-copy" @click="copyCard">Copy Account</button>
      </div>
      <div class="pay-alip">
        <a class="btn bankcard-down" :href="qcode" :download="`pay-${orderInfo.amount}.png`">Download Qrcode</a>
      </div>
    </div>
    <!-- timer -->
    <TimerComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
    <!-- Notice -->
    <div class="pannel-simp attention">
      <p class=""><b>Notice:&nbsp;</b><span style="color: red;">Please do not pay beyond the time limit, </span>otherwise it cannot be recovered!!!</p>
      <p class=""><b>Notice:&nbsp;</b><span style="color: red;">Please do not modify the amount, </span>otherwise it cannot be recovered!!!</p>
      <p class=""><b>Notice:&nbsp;</b><span style="color: red;">Not checked before the next day, </span>cannot be recovered!!!</p>
      <p class=""><b>Notice:&nbsp;</b>Payment successful confirmation time: <span style="color: red;">5 to 10 minutes</span>, After payment, The account has not been received for more than <span style="color: red;">10 minutes,</span> Please contact customer service to check the order!</p>
    </div>
  </div>
</template>

<script>
import TimerComp from "@/views/comp/timer_comp";
export default {
  components: {
    TimerComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    qcode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    // 复制
    copyCard: function () {
      this.$parent.copySome(
        this.orderInfo.accountNumber +
          "\n" +
          this.orderInfo.backCode +
          "\n" +
          this.orderInfo.ownerName,
        1,
        () => {
          this.cptyAmountIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.copyErr();
        }
      );
    },
    //
    downQr: function () {
      this.$refs.qrImg.click;
    },
  },
  filters: {
    formatCurrency: function (amount) {
      return new Intl.NumberFormat("zh-CN", {
        style: "currency",
        currency: "VND",
        minimumFractionDigits: 2,
      }).format(amount);
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
