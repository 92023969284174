<!-- changelly -->
<template>
  <div class="">
    <!--提示-->
    <div class="title">
      <div style="margin: 1.55em 0 0.5em  0;">
        <h2>معلومات الطلب</h2>
      </div>
    </div>
    <!-- 输入账户 -->
    <div class="vdf-modal" v-if="showNums">
      <div class="vdf-modal-content">
        <div style="font-size: 0.95em;">الرجاء ادخال الرقم الذى ستقوم بالتحويل منه المكون من 11 رقم</div>
        <div class="vdf-phone-container">
          <input type="tel" class="vdf-phone-number vdf-phone-number-noBottom " value="0" readonly="" disabled="">
          <input type="tel" class="vdf-phone-number vdf-phone-number-noBottom " value="1" readonly="" disabled="">
          <input type="tel" v-model="num2" ref="num2" class="vdf-phone-number" maxlength="1" @input="aaa(2)" @keydown.backspace="aaaBackspace(2)">
          <input type="tel" v-model="num3" ref="num3" class="vdf-phone-number" maxlength="1" @input="aaa(3)" @keydown.backspace="aaaBackspace(3)">
          <input type="tel" v-model="num4" ref="num4" class="vdf-phone-number" maxlength="1" @input="aaa(4)" @keydown.backspace="aaaBackspace(4)">
          <input type="tel" v-model="num5" ref="num5" class="vdf-phone-number" maxlength="1" @input="aaa(5)" @keydown.backspace="aaaBackspace(5)">
          <input type="tel" v-model="num6" ref="num6" class="vdf-phone-number" maxlength="1" @input="aaa(6)" @keydown.backspace="aaaBackspace(6)">
          <input type="tel" v-model="num7" ref="num7" class="vdf-phone-number" maxlength="1" @input="aaa(7)" @keydown.backspace="aaaBackspace(7)">
          <input type="tel" v-model="num8" ref="num8" class="vdf-phone-number" maxlength="1" @input="aaa(8)" @keydown.backspace="aaaBackspace(8)">
          <input type="tel" v-model="num9" ref="num9" class="vdf-phone-number" maxlength="1" @input="aaa(9)" @keydown.backspace="aaaBackspace(9)">
          <input type="tel" v-model="num10" ref="num10" class="vdf-phone-number" maxlength="1" @input="aaa(10)" @keydown.backspace="aaaBackspace(10)">
        </div>
        <h2 id="payment-modal-error" style="display:none;color: red;">يجب أن يكون رقم حساب المحفظة المالية مكونًا من 11 رقمًا، على سبيل المثال: 01012345678</h2>
        <button class="btn bankcard-copy" @click="subVdfPayCard">حسنا</button>
      </div>
    </div>

    <!---->
    <div class="title">
      <div>
        <span style="font-size:0.7em;">{{cardNo}}</span>
      </div>
      <div class="bankcard-amount">
        <span style="font-size:1.5em;">
          <b>{{orderInfo.amount|formatCurrency}}</b>
        </span>
      </div>
    </div>

    <!-- 复制区域 -->
    <div class="pannel" style="">
      <table class="details" style="">
        <tr>
          <td>
            <button v-on:click="copyCardNo" class="btn copy">نسخ الحساب</button>
          </td>
          <td>
            <span v-if="copyCardNoIf" style="color: green;"><b>{{orderInfo.cardNo}}</b></span>
            <span v-else style="color: blue;"><b>{{orderInfo.cardNo}}</b></span>
          </td>
          <td>الحساب:</td>
        </tr>
        <tr>
          <td>
            <button @click="copyAmount" class="btn copy">نسخ المبلغ</button>
          </td>
          <td>
            <span v-if="cptyAmountIf" style="color: green;"><b>{{orderInfo.amount|formatCurrency}}</b></span>
            <span v-else style="color: red;"><b>{{orderInfo.amount|formatCurrency}}</b></span>
          </td>
          <td>المبلغ:</td>
        </tr>
      </table>
    </div>

    <!---->
    <div class="title">
      <div>
        <b>Order ID : {{orderInfo.orderId}}</b>
      </div>
    </div>
    <!-- timer -->
    <TimerVdfComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
    <!-- Notice -->
    <div class="pannel-simp attention">
      <p class="">
        بعد الحصول على حساب الاستلام، يرجى استخدام المحفظة الإلكترونية للدفع .
      </p>
      <p class="">
        تعتمد كل إعادة شحن بشكل أساسي علي حساب الاستلام الفعلي لهذا الموقع ، قبل الحفظ ستكون مسئوولا عن الخسارة الناجمة عن التحويل المصرفي الجانبي لحساب المال .
      </p>
      <p class="">
        عند الشحن ، يجب أن يكون المبلغ الذي تم إدخاله في الطلب متسقا مع مبلغ الدفع الفعلي ، وإلا فلن يتم إضافته تلقائياً للحساب ، في حالة عدم وصول الشحن ، يرجي الاتصال بخدمة العملاء لتقديم تفاصيل الدفع و التحقق من خلال لقطة شاشة رقم العضويه .
      </p>
    </div>
    <!-- logo -->
    <div class="pannel-pay-title" style="margin-top:20px;padding: 10px;">
      <img src="/imgs/vodafone_logo_all.png" />
    </div>
  </div>
</template>

<script>
import * as apis from "@/apis/apis";
import TimerVdfComp from "@/views/comp/timer_vdf_comp";
export default {
  components: {
    TimerVdfComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      showNums: true,
      num2: null,
      num3: null,
      num4: null,
      num5: null,
      num6: null,
      num7: null,
      num8: null,
      num9: null,
      num10: null,
      cardNo: "none",
      // 复制相关
      copyCardNoIf: false,
      cptyAmountIf: false,
    };
  },
  computed: {},
  created() {
    if (this.orderInfo.payCardNo == null || this.orderInfo.payCardNo == "") {
      this.showNums = true;
    } else {
      this.cardNo = this.orderInfo.payCardNo;
      this.showNums = false;
    }
  },
  mounted() {
    if (this.showNums) {
      //第一个输入框获取焦点
      this.$refs["num2"].focus();
    }
  },
  methods: {
    //
    aaa(i) {
      // 获取当前输入框的值
      let num = this[`num${i}`];
      if (num == "") {
        // eslint-disable-next-line
      } else {
        // 下一个获取焦点
        if (i < 10) {
          this.$refs["num" + (i + 1)].focus();
          this.$refs["num" + (i + 1)].select();
        }
      }
    },
    aaaBackspace(i) {
      let numValue = this[`num${i}`];
      if (numValue == null || numValue == "") {
        if (i > 2) {
          this.$refs["num" + (i - 1)].focus();
        }
      }
    },
    // 提交复制出错
    subVdfPayCard() {
      this.cardNo =
        "01" +
        this.num2 +
        this.num3 +
        this.num4 +
        this.num5 +
        this.num6 +
        this.num7 +
        this.num8 +
        this.num9 +
        this.num10;
      if (this.cardNo.length != 11) {
        return;
      }
      apis
        .subVdfPayCard({
          id: this.orderInfo.orderId,
          cardNo: this.cardNo,
        })
        // eslint-disable-next-line
        .then((res) => {
          this.showNums = false;
        })
        // eslint-disable-next-line
        .catch((err) => {
          console.log(err);
        });
    },
    // 复制
    copyCardNo: function () {
      this.$parent.copySome(
        this.orderInfo.cardNo,
        1,
        () => {
          this.copyCardNoIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.copyErr();
        }
      );
    },
    // 复制
    copyAmount: function () {
      this.$parent.copySome(
        this.orderInfo.amount,
        2,
        () => {
          this.cptyAmountIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.copyErr();
        }
      );
    },
  },
  filters: {
    formatCurrency: function (amount) {
      return new Intl.NumberFormat("default", {
        style: "currency",
        currency: "EGP",
        minimumFractionDigits: 2,
      }).format(amount);
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
