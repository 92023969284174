<!-- changelly -->
<template>
  <div class="">
    <!-- 图片标题 -->
    <div class="">
      <div class="pannel-pay-title">
        <img v-if="orderInfo.payType=='alipay'" src="/imgs/zfb.jpg" />
        <img v-if="orderInfo.payType=='weixin'" src="/imgs/wx.jpg" />
        <img v-if="orderInfo.payType=='douyin'" src="/imgs/douyin.png" />
        <div v-if="orderInfo.payType=='changelly'" class="title">
          <h1>{{$t("main['安全支付']")}}</h1>
        </div>
      </div>
    </div>
    <!-- 订单号 -->
    <div class="pannel" style="">
      <table class="details" style="">
        <tr>
          <td>{{$t("main['订单号']")}}:&nbsp;</td>
          <td colspan="2">
            <span style="color: grey;"> {{orderInfo.orderId}}
            </span>
          </td>
        </tr>
        <tr>
          <td>{{$t("main['金额']")}}:&nbsp;</td>
          <td>
            <span style="color: red;"><b>{{orderInfo.amount}}</b></span>
          </td>
        </tr>
      </table>
    </div>

    <!-- 插槽出口 -->
    <slot></slot>

  </div>
</template>

<script>
export default {
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
