import { render, staticRenderFns } from "./pay_url_comp.vue?vue&type=template&id=c38db160&scoped=true"
import script from "./pay_url_comp.vue?vue&type=script&lang=js"
export * from "./pay_url_comp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c38db160",
  null
  
)

export default component.exports