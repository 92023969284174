export default {
    main: {
        首页: "मुखपृष्ठ",
        订单生成中: 'आदेश उत्पादन',
        请稍候: 'कृपया प्रतीक्षा करें...',
        安全支付: 'Secure Payment',
        订单号: 'OrderID',
        金额: 'Amount',
        支付提示: 'Tips',
        请在: 'please at ',
        秒内: ' seconds ',
        完成: 'finish ',
        支付: 'payment ',
        超时会自动: 'timeout will auto ',
        取消: 'cancel ',
        剩余时间: 'Time left',
        订单不存在: 'Order None',
        订单已失效: 'Order expired',
        订单已失效Memo: 'Please place an order again or wait for the payment to arrive !',
        订单已关闭: 'Order closed',
        订单异常: 'Order Abnormality',
        发生错误: 'An error occurred',
        订单生成失败: 'Order generation failed',
        暂无可用的订单: 'No orders available',
    }
    //   route: {
    //     dashboard: 'मुखपृष्ठ',
    //     documentation: 'दस्तावेज़',
    //     guide: 'मार्गदर्शक पन्ने',
    //     permission: 'अनुमति परीक्षण पृष्ठ',
    //     rolePermission: 'भूमिका अनुमतियाँ',
    //     pagePermission: 'पेज अनुमतियाँ',
    //     directivePermission: 'आदेश प्राधिकारी',
    //     icons: 'आइकन',
    //     components: 'अवयव',
    //     tinymce: 'रिच टेक्स्ट एडिटर',
    //     markdown: 'markdown',
    //     jsonEditor: 'JSON संपादक',
    //     dndList: 'सूची खींचें और छोड़ें',
    //     splitPane: 'स्प्लिटपेन',
    //     avatarUpload: 'अवतार अपलोड',
    //     dropzone: 'ड्रॉप क्षेत्र',
    //     sticky: 'चिपचिपा',
    //     countTo: 'तक गिनती',
    //     componentMixin: 'विजेट',
    //     backToTop: 'वापस शीर्ष पर',
    //     dragDialog: 'संवाद खींचें',
    //     dragSelect: 'चयन खींचें',
    //     dragKanban: 'खींचने योग्य बिलबोर्ड',
    //     charts: 'चार्ट',
    //     keyboardChart: 'कीबोर्ड आरेख',
    //     lineChart: 'पंक्ति चार्ट',
    //     mixChart: 'मिश्रित चार्ट',
    //     example: 'व्यापक उदाहरण',
    //     nested: 'रूट नेस्टिंग',
    //     menu1: 'मेनू 1',
    //     'menu1-1': 'मेनू 1-1',
    //     'menu1-2': 'मेनू 1-2',
    //     'menu1-2-1': 'मेनू 1-2-1',
    //     'menu1-2-2': 'मेनू 1-2-2',
    //     'menu1-3': 'मेनू 1-3',
    //     menu2: 'मेनू 2',
    //     Table: 'मेज़',
    //     dynamicTable: 'गतिशील तालिका',
    //     dragTable: 'तालिका खींचें',
    //     inlineEditTable: 'तालिका के भीतर संपादित करें',
    //     complexTable: 'व्यापक तालिका',
    //     tab: 'टैब',
    //     form: 'रूप',
    //     createArticle: 'लेख बनाएं',
    //     editArticle: 'लेख संपादित करें',
    //     articleList: 'आलेख सूची',
    //     errorPages: 'त्रुटि पृष्ठ',
    //     page401: '401',
    //     page404: '404',
    //     errorLog: 'त्रुटि लॉग',
    //     excel: 'एक्सेल',
    //     exportExcel: 'एक्सेल में निर्यात करें',
    //     selectExcel: 'चयनित आइटम निर्यात करें',
    //     mergeHeader: 'बहु-स्तरीय हेडर निर्यात करें',
    //     uploadExcel: 'एक्सेल अपलोड करें',
    //     zip: 'ज़िप',
    //     pdf: 'पीडीएफ',
    //     exportZip: 'ज़िप निर्यात करें',
    //     theme: 'छीलना',
    //     clipboardDemo: 'क्लिपबोर्ड',
    //     i18n: 'भूमंडलीकरण',
    //     externalLink: 'बाहरी लिंक',
    //     profile: 'व्यक्तिगत केंद्र',
    //     对接信息: 'डॉकिंग जानकारी',
    //     订单管理: 'प्रबंधन को आदेश दें',
    //     电费订单管理: 'बिजली बिल आदेश प्रबंधन',
    //     电费订单上传: 'बिजली बिल ऑर्डर अपलोड करें',
    //     代付订单管理: 'भुगतान आदेश प्रबंधन',
    //     代付订单上传: 'भुगतान आदेश अपलोड करें',
    //     对账与统计: 'सुलह और आँकड़े',
    //     订单综合统计: 'व्यापक आदेश आँकड़े',
    //     订单缴费明细: 'आदेश भुगतान विवरण',
    //     授信扣款记录: 'क्रेडिट कटौती रिकॉर्ड',
    //   },
    //   navbar: {
    //     dashboard: 'मुखपृष्ठ',
    //     github: 'परियोजना का पता',
    //     logOut: 'साइन आउट',
    //     profile: 'व्यक्तिगत केंद्र',
    //     theme: 'छीलना',
    //     size: 'लेआउट का आकार',
    //     余额: 'संतुलन',
    //     修改密码: 'पासवर्ड बदलें',
    //     动态码: 'गतिशील कोड',
    //   },
    //   login: {
    //     title: 'सिस्टम लॉगिन',
    //     logIn: 'लॉग इन करें',
    //     username: 'खाता',
    //     password: 'पासवर्ड',
    //     any: 'जो चाहो भर दो',
    //     thirdparty: 'के साथ साइन इन करें',
    //     thirdpartyTips: 'इसे स्थानीय स्तर पर अनुकरण नहीं किया जा सकता, कृपया अपने व्यवसाय के आधार पर अनुकरण करें! ! !',
    //     动态码: 'गतिशील कोड',
    //   },
    //   documentation: {
    //     documentation: 'दस्तावेज़',
    //     github: 'Github पता'
    //   },
    //   permission: {
    //     addRole: 'नई भूमिका जोड़ें',
    //     editPermission: 'अनुमतियाँ संपादित करें',
    //     roles: 'आपकी अनुमतियाँ',
    //     switchRoles: 'अनुमतियाँ स्विच करें',
    //     tips: 'कुछ मामलों में, वी-अनुमति का उपयोग करना उचित नहीं है। उदाहरण के लिए: एलिमेंट-यूआई का एल-टैब या एल-टेबल-कॉलम और अन्य दृश्य जो गतिशील रूप से डीओएम प्रस्तुत करते हैं। आप इसे केवल मैन्युअल रूप से v-if सेट करके ही कर सकते हैं।',
    //     delete: 'मिटाना',
    //     confirm: 'ज़रूर',
    //     cancel: 'रद्द करना',
    //   },
    //   guide: {
    //     description: 'परिचयात्मक पृष्ठ उन कुछ लोगों के लिए बहुत उपयोगी है जो पहली बार परियोजना में प्रवेश कर रहे हैं। आप परियोजना के कार्यों का संक्षेप में परिचय दे सकते हैं। यह डेमो पर आधारित है',
    //     button: 'बूट खोलो',
    //   },
    //   components: {
    //     documentation: 'दस्तावेज़',
    //     tinymceTips: 'रिच टेक्स्ट प्रबंधन बैकएंड का एक मुख्य कार्य है, लेकिन यह कई कमियों वाला स्थान भी है। रिच टेक्स्ट को चुनने की प्रक्रिया में मुझे भी बहुत सारे चक्कर लगाने पड़े, मैंने मूल रूप से बाज़ार में उपलब्ध सभी सामान्य रिच टेक्स्ट का उपयोग किया है, और अंत में तौला और टिनिम्स को चुना। अधिक विस्तृत तुलना और समृद्ध पाठ के परिचय के लिए, देखें',
    //     dropzoneTips: 'चूँकि हमारी कंपनी के व्यवसाय की विशेष आवश्यकताएँ हैं और इसे Qiniu में स्थानांतरित करने की आवश्यकता है, इसलिए हमने किसी तीसरे पक्ष का उपयोग नहीं किया और इसे स्वयं पैकेज करने का विकल्प चुना। कोड बहुत सरल है, आप विशिष्ट कोड यहां @/components/Dropzone देख सकते हैं',
    //     stickyTips: 'जब पृष्ठ पूर्व निर्धारित स्थिति तक स्क्रॉल करता है, तो यह शीर्ष पर अवशोषित हो जाएगा',
    //     backToTopTips1: 'जब पृष्ठ को निर्दिष्ट स्थान पर स्क्रॉल किया जाता है, तो निचले दाएं कोने में शीर्ष पर वापसी बटन दिखाई देगा।',
    //     backToTopTips2: 'बटन की शैली, दिखाएँ/छिपाएँ, उपस्थिति ऊँचाई और वापसी स्थिति को अनुकूलित किया जा सकता है यदि आपको टेक्स्ट संकेतों की आवश्यकता है, तो आप बाहरी रूप से एलिमेंट के एल-टूलटिप तत्व का उपयोग कर सकते हैं।',
    //     imageUploadTips: 'चूंकि जब मैं इसका उपयोग कर रहा था तो इसमें केवल vue@1 संस्करण था, और यह मॉकजेएस के साथ असंगत था, इसलिए मैंने इसे स्वयं संशोधित किया यदि आप इसका उपयोग करना चाहते हैं, तो पहले आधिकारिक संस्करण का उपयोग करें।',
    //   },
    //   table: {
    //     dynamicTips1: 'निश्चित हेडर, हेडर क्रम के अनुसार क्रमबद्ध',
    //     dynamicTips2: 'हेडर निश्चित नहीं है, क्लिक क्रम के अनुसार क्रमबद्ध है',
    //     dragTips1: 'डिफ़ॉल्ट आदेश',
    //     dragTips2: 'खींचने के बाद ऑर्डर करें',
    //     title: 'शीर्षक',
    //     importance: 'महत्त्व',
    //     type: 'प्रकार',
    //     remark: 'समीक्षा',
    //     search: 'खोज',
    //     add: 'इसमें जोड़ें',
    //     export: 'निर्यात',
    //     reviewer: 'आलोचक',
    //     id: 'क्रम संख्या',
    //     date: 'समय',
    //     author: 'लेखक',
    //     readings: 'पढ़ने की संख्या',
    //     status: 'राज्य',
    //     actions: 'प्रचालन',
    //     edit: 'संपादन करना',
    //     publish: 'मुक्त करना',
    //     draft: 'मसौदा',
    //     delete: 'मिटाना',
    //     cancel: 'रद्द करना',
    //     confirm: 'ज़रूर',
    //     noData: 'कोई नहीं',
    //     yes: 'हाँ',
    //     no: 'नहीं',
    //     operation: 'प्रचालन',
    //     input: 'कृपया दर्ज करें',
    //     add: 'नया',
    //     inputNumber: 'कृपया संख्या दर्ज करें',
    //   },
    //   notice: {
    //     alreadyCancel: 'रद्द',
    //     areYouSure: 'आपको यकीन है? ? ?',
    //     notice: 'संकेत देना',
    //     加载memo: 'लोड हो रहा है कृपया प्रतीक्षा करें...',
    //   },
    //   example: {
    //     warning: 'पेज बनाएं और संपादित करें को कीप-अलाइव द्वारा कैश नहीं किया जा सकता क्योंकि कीप-अलाइव का समावेश वर्तमान में रूटिंग के आधार पर कैशिंग का समर्थन नहीं करता है, इसलिए यह वर्तमान में घटक नाम के आधार पर कैश किया गया है। यदि आप समान कैशिंग प्रभाव प्राप्त करना चाहते हैं, तो आप लोकलस्टोरेज जैसे ब्राउज़र कैशिंग समाधान का उपयोग कर सकते हैं। या कीप-अलाइव का उपयोग न करें और सभी पेजों को सीधे कैश करें। विस्तृत जानकारी देखें'
    //   },
    //   errorLog: {
    //     tips: 'कृपया ऊपरी दाएं कोने में बग आइकन पर क्लिक करें',
    //     description: 'वर्तमान प्रबंधन बैकएंड मूल रूप से स्पा के रूप में है, जो उपयोगकर्ता अनुभव को बढ़ाता है, लेकिन साथ ही यह पृष्ठ समस्याओं की संभावना भी बढ़ाता है, एक छोटी सी चूक से पूरे पृष्ठ में गतिरोध उत्पन्न हो सकता है। सौभाग्य से, Vue आधिकारिक वेबसाइट अपवादों को पकड़ने और संभालने के लिए एक विधि प्रदान करती है, जहां आप त्रुटि प्रबंधन या अपवाद रिपोर्टिंग कर सकते हैं।',
    //     documentation: 'दस्तावेज़ परिचय',
    //   },
    //   excel: {
    //     export: 'निर्यात',
    //     selectedExport: 'चयनित आइटम निर्यात करें',
    //     placeholder: 'कृपया फ़ाइल नाम दर्ज करें (डिफ़ॉल्टexcel-list)'
    //   },
    //   zip: {
    //     export: 'निर्यात',
    //     placeholder: 'कृपया फ़ाइल नाम दर्ज करें (डिफ़ॉल्टfile)'
    //   },
    //   pdf: {
    //     tips: 'पीडीएफ डाउनलोड करने के कार्य को लागू करने के लिए यहां window.print() का उपयोग करें'
    //   },
    //   theme: {
    //     change: 'छीलना',
    //     documentation: 'त्वचा परिवर्तन दस्तावेज़',
    //     tips: 'युक्तियाँ: यह नेवबार पर थीम-चयन से भिन्न है, वे दो अलग-अलग त्वचा-बदलने वाली विधियां हैं, प्रत्येक अलग-अलग अनुप्रयोग परिदृश्यों के साथ हैं। कृपया विवरण के लिए दस्तावेज़ देखें।'
    //   },
    //   tagsView: {
    //     refresh: 'ताज़ा',
    //     close: 'बंद',
    //     closeOthers: 'अन्य बंद करें',
    //     closeAll: 'सब बंद करें',
    //     首页: 'मुखपृष्ठ',
    //   },
    //   settings: {
    //     title: 'सिस्टम लेआउट कॉन्फ़िगरेशन',
    //     theme: 'थीम रंग',
    //     tagsView: 'टैग-व्यू चालू करें',
    //     fixedHeader: 'निश्चित शीर्षलेख',
    //     sidebarLogo: 'साइडबार लोगो',
    //   },
    //   //核销后台
    //   wmerch: {
    //     apis: {
    //       对接编号: 'डॉकिंग नंबर',
    //       签名密钥: 'हस्ताक्षर कुंजी',
    //       网关地址: 'प्रवेशद्वार का पता',
    //       上单接口: 'ऑर्डर इंटरफ़ेस',
    //       查单接口: 'क्वेरी ऑर्डर इंटरफ़ेस',
    //       撤单接口: 'रद्दीकरण इंटरफ़ेस',
    //       异步回调IP: 'अतुल्यकालिक कॉलबैक आईपी',
    //       在线文档: 'ऑनलाइन दस्तावेजीकरण',
    //       对接签名文档: 'हस्ताक्षर दस्तावेजों को जोड़ना',
    //       话费上单接口文档: 'टेलीफोन बिल ऑर्डरिंग इंटरफ़ेस दस्तावेज़',
    //       电费上单接口文档: 'बिजली बिल ऑर्डरिंग इंटरफ़ेस दस्तावेज़',
    //       查单接口文档: 'क्वेरी इंटरफ़ेस दस्तावेज़',
    //       撤单接口文档: 'रद्दीकरण इंटरफ़ेस दस्तावेज़',
    //       异步回调文档: 'अतुल्यकालिक कॉलबैक दस्तावेज़ीकरण',
    //       php代码例子: 'php कोड उदाहरण',
    //       IP白名单设置: 'आईपी वेतसूची सेटिंग्स',
    //       是否启用: 'सक्षम करना है या नहीं',
    //       填写IP地址: 'आईपी एड्रेस भरें',
    //       填写IP地址Memo: 'एकाधिक आईपी को रिक्त स्थान या न्यूलाइन से अलग करें',
    //       其他设置: 'अन्य सेटिंग',
    //       订单超时时间: 'ऑर्डर का समय समाप्त',
    //       订单超时时间Memo: 'टाइमआउट अवधि पृष्ठभूमि के माध्यम से दिए गए मैन्युअल ऑर्डर तक सीमित है, और इंटरफ़ेस के माध्यम से दिए गए ऑर्डर प्रभावित नहीं होंगे।',
    //       部分支付是否回调: 'क्या भुगतान का कुछ हिस्सा वापस बुलाया गया है?',
    //       部分支付是否回调Memo1: 'नोट: यह फ़ंक्शन एक बार सक्षम हो गया है, कृपया कॉलबैक तर्क को ठीक से संभालें! ! !',
    //       部分支付是否回调Memo2: 'यदि आपको कॉलबैक संदेश प्राप्त नहीं होता है, तो ऑर्डर को रद्द या समाप्त मान लें।',
    //       需要回调: 'कॉलबैक आवश्यक है',
    //       不用回调: 'कॉलबैक की आवश्यकता नहीं है',
    //       //
    //       请输入: 'कृपया दर्ज करें',
    //       秒: 'दूसरा',
    //       更新: 'नवीकरण',
    //       重置: 'रीसेट',
    //       启用: 'सक्षम',
    //       禁用: 'अक्षम करना',
    //       保存: 'बचाना',
    //     },
    //   },
    //   trip: {
    //     wof_order_comp: {
    //       核销单号: 'सत्यापन आदेश संख्या',
    //       户号: 'खाता संख्या',
    //       油卡卡号: 'ईंधन कार्ड संख्या',
    //       核销商编号: 'हामीदार संख्या',
    //       核销商名称: 'हामीदार का नाम',
    //       金额: 'मात्रा',
    //       地区: 'क्षेत्र',
    //       系统单号: 'सिस्टम ऑर्डर नंबर',
    //       户号错误: 'ग़लत खाता संख्या',
    //       错误: 'गलती',
    //       正常: 'सामान्य',
    //       授信状态: 'क्रेडिट स्थिति',
    //       授信额充足: 'पर्याप्त क्रेडिट सीमा',
    //       授信额已预警: 'क्रेडिट सीमा की चेतावनी दी गई है',
    //       派单状态: 'प्रेषण स्थिति',
    //       开启派单: 'प्रेषण प्रारंभ करें',
    //       停止派单: 'ऑर्डर भेजना बंद करें',
    //       '创建日期-起': 'रचना तिथि - से',
    //       '创建日期-终': 'निर्माण तिथि समाप्ति',
    //       '超时日期-起': 'समय समाप्ति दिनांक - से',
    //       '超时日期-终': 'टाइमआउट तिथि-समाप्ति',
    //       '回调日期-起': 'कॉलबैक दिनांक - से',
    //       '回调日期-终': 'कॉलबैक तिथि समाप्ति',
    //       '结束日期-起': 'अंतिम तिथि - से',
    //       '结束日期-终': 'अंतिम तिथि-अंत',
    //       全部: 'सभी',
    //       待检测: 'परीक्षण करने के लिए',
    //       待使用: 'इस्तेमाल किया जाएगा',
    //       取单中: 'ऑर्डर लेना',
    //       待缴费: 'लंबित भुगतान',
    //       部分付: 'आंशिक भुगतान',
    //       已缴费: 'चुकाया गया',
    //       未缴费: 'भुगतान नहीं',
    //       异常的: 'असामान्य',
    //       已取消: 'रद्द',
    //       未回调: 'वापस नहीं बुलाया गया',
    //       未结束: 'समाप्त नहीं हुआ है',
    //       已结束: 'ऊपर',
    //       根据条件统计: 'स्थितियों पर आधारित आँकड़े',
    //       实时库存统计: 'वास्तविक समय सूची आँकड़े',
    //       根据条件回调: 'शर्तों के आधार पर कॉलबैक',
    //       根据条件撤单: 'शर्तों के आधार पर ऑर्डर रद्द करें',
    //       根据条件导出: 'शर्तों के आधार पर निर्यात करें',
    //       系统单号: 'सिस्टम ऑर्डर नंबर',
    //       客户名称: 'ग्राहक का नाम',
    //       核销商编号: 'हामीदार संख्या',
    //       核销商名称: 'हामीदार का नाम',
    //       手机: 'सेलफोन',
    //       运营商: 'ऑपरेटर',
    //       户号: 'खाता संख्या',
    //       类型: 'प्रकार',
    //       地区: 'क्षेत्र',
    //       油卡卡号: 'ईंधन कार्ड संख्या',
    //       代付账户: 'भुगतान खाता',
    //       代付地址: 'भुगतान पता',
    //       金额: 'मात्रा',
    //       已充: 'पहले ही चार्ज किया जा चुका है',
    //       最低: 'सबसे कम',
    //       金额状态: 'राशि की स्थिति',
    //       查询金额: 'क्वेरी राशि',
    //       拆单: 'क्रम विभाजित करें',
    //       已拆: 'ध्वस्त',
    //       未拆: 'ध्वस्त नहीं किया गया',
    //       本次应充: 'इस बार शुल्क लिया जाना चाहिए',
    //       拉单次数: 'खींचो',
    //       缴费: 'फीस का भुगतान करें',
    //       零充值: 'शून्य रिचार्ज',
    //       已全充: 'पूर्णतःउर्जित',
    //       部分充: 'आंशिक प्रभार',
    //       已超充: 'अधिक शुल्क',
    //       其他: 'अन्य',
    //       状态: 'राज्य',
    //       回调: 'वापस बुलाओ',
    //       已回调: 'वापस बुलाया',
    //       不需要: 'अनावश्यक',
    //       未回调: 'वापस नहीं बुलाया गया',
    //       创建时间: 'रचना समय',
    //       超时时间: 'ओवरटाइम समय',
    //       回调时间: 'कॉलबैक समय',
    //       结束时间: 'अंत समय',
    //       备注: 'टिप्पणी',
    //       错误: 'गलती',
    //       户号: 'खाता संख्या',
    //       正常: 'सामान्य',
    //       JD黑户错误: 'जेडी ब्लैक खाता त्रुटि',
    //       是否改单: 'परिवर्तन',
    //       撤单: 'आदेश रद्द',
    //       强制撤单: 'जबरन ऑर्डर रद्द करना',
    //       强制回调: 'जबरन कॉलबैक',
    //       异常恢复: 'अपवाद पुनर्प्राप्ति',
    //       详细信息header: 'विस्तृत',
    //       详情: 'विवरण',
    //       详细信息: 'विवरण',
    //       修改时间: 'समय बदलो',
    //       户号金额类型: 'खाता संख्या राशि प्रकार',
    //       GW公司ID: 'जीडब्ल्यू कंपनी आईडी',
    //       GW公司名: 'जीडब्ल्यू कंपनी का नाम',
    //       本次已充: 'इस बार पहले ही चार्ज कर लिया गया है',
    //       金额变动前: 'राशि बदलने से पहले',
    //       回调地址: 'कॉलबैक पता',
    //       上次缴费时间: 'अंतिम भुगतान का समय',
    //       上次取单时间: 'अंतिम ऑर्डर लेने का समय',
    //       上次查单时间: 'अंतिम जांच का समय',
    //       异常时间: 'असामान्य समय',
    //       上次使用时间: 'अंतिम बार उपयोग किया गया समय',
    //       检测错误数: 'पहचान त्रुटियों की संख्या',
    //       查余额次数: 'शेष राशि की जांच की संख्या',
    //       绑定支付单: 'भुगतान आदेश बाइंड करें',
    //       查单: 'सूची जांचें',
    //       调整: 'समायोजन',
    //       订单金额: 'ऑर्डर करने की राशि',
    //       已付金额: 'राशि का भुगतान',
    //       拆单应付: 'भुगतान करने के लिए आदेश विभाजित करें',
    //       应付金额: 'देय राशियाँ',
    //       本次已付: 'इस बार भुगतान किया',
    //       修改已付缴费: 'भुगतान किए गए भुगतान को संशोधित करें',
    //       备注: 'टिप्पणी',
    //       高级验证: 'उन्नत सत्यापन',
    //       时间: 'समय',
    //       账户: 'खाता',
    //       最小缴费: 'कम से कम भुगतान',
    //       金额: 'मात्रा',
    //       有效金额: 'प्रभावी राशि',
    //       立即获取最新的: 'अभी नवीनतम प्राप्त करें',
    //       实时统计: 'वास्तविक समय आँकड़े',
    //       包含无授信额的核销: 'इसमें बिना क्रेडिट सीमा के बट्टे खाते में डालना शामिल है',
    //       包含停止派单的核销: 'जिसमें स्टॉप डिस्पैच ऑर्डर को राइट-ऑफ करना भी शामिल है',
    //       单: 'एक',
    //       元: 'युआन',
    //       可用核销单: 'उपलब्ध सत्यापन प्रपत्र:',
    //       核销单总额: 'बट्टे खाते में डालने के आदेशों की कुल राशि:',
    //       待缴库存: 'भुगतान की जाने वाली सूची:',
    //       挂起库存: 'लंबित सूची:',
    //       可用库存: 'मौजूदा भंडार',
    //       刷新统计: 'आँकड़े ताज़ा करें',
    //       强制撤单notice: 'कृपया उन्नत पासवर्ड (द्वितीयक पासवर्ड या डायनेमिक पासवर्ड) दर्ज करें',
    //       强制撤单title: 'एक बार रद्द होने के बाद, वापस जाने का कोई रास्ता नहीं है। कृपया इस बात पर ध्यान दें कि धनराशि सही है या नहीं।',
    //       请输入高级验证: 'कृपया उन्नत सत्यापन दर्ज करें',
    //       条件撤单notice: 'कृपया उन्नत पासवर्ड (द्वितीयक पासवर्ड या डायनेमिक पासवर्ड) दर्ज करें',
    //       条件撤单title: 'शर्तों के आधार पर ऑर्डर रद्द करने में लंबा समय लग सकता है, क्या आप निश्चित हैं?',
    //       核销条件撤单notice: 'इसमें काफी समय लग सकता है। ऑर्डर रद्द करने के बाद, कृपया दोबारा पुष्टि करें कि क्या अभी भी रद्द किए गए ऑर्डर हैं।',
    //       核销条件撤单title: 'शर्तों के आधार पर ऑर्डर रद्द करें',
    //       条件回调notice: 'कृपया उन्नत पासवर्ड (द्वितीयक पासवर्ड या डायनेमिक पासवर्ड) दर्ज करें',
    //       条件回调title: 'शर्तों के आधार पर जबरन कॉलबैक में काफी समय लग सकता है, क्या आप निश्चित हैं?',
    //       条件回调msg: 'बैकग्राउंड एक-एक करके वापस कॉल कर रहा है, कृपया थोड़ी देर प्रतीक्षा करें।',
    //       根据条件实时统计数据如下: 'स्थितियों पर आधारित वास्तविक समय के आँकड़े इस प्रकार हैं:',
    //       补单title: 'नोट: सत्यापन फॉर्म को तब तक संशोधित न करें जब तक कि विशेष परिस्थितियां न हों; सत्यापन फॉर्म को संशोधित करने से उसी समय व्यापारी के ऑर्डर में संशोधन नहीं होगा, यदि आपको व्यापारी के ऑर्डर को संशोधित करने की आवश्यकता है, तो कृपया ऑर्डर भरें या इसे व्यापारी के ऑर्डर में संशोधित करें .',
    //       查单: 'सूची जांचें',
    //       编辑招聘者: 'भर्तीकर्ता संपादित करें',
    //     },
    //     wof_order_statis_comp: {
    //       起始日期: 'आरंभ तिथि (आज डिफ़ॉल्ट)',
    //       结束日期: 'समाप्ति तिथि (अब तक डिफ़ॉल्ट)',
    //       核销商: 'ग्राहक',
    //       统计日期: 'सांख्यिकी दिनांक',
    //       核销商名: 'व्यापार का नाम लिखें',
    //       单笔金额: 'एकल राशि',
    //       标记信息: 'टैग जानकारी',
    //       合计: 'कुल',
    //       全部: 'सभी',
    //       统计日期: 'सांख्यिकी दिनांक',
    //       核销商名: 'व्यापार का नाम लिखें',
    //       上级核销: 'वरिष्ठों द्वारा बट्टे खाते में डालना',
    //       单笔金额: 'एकल राशि',
    //       标记信息: 'टैग जानकारी',
    //       上单数量: 'आदेश की मात्रा',
    //       成功笔数: 'सफल लेनदेन की संख्या',
    //       缴费总额: 'कुल भुगतान',
    //       重新统计这天: 'इस दिन का स्मरण करें',
    //       重新统计这天notice: 'जब डेटा की मात्रा बहुत बड़ी हो, तो इसमें कुछ समय लग सकता है क्या आप वाकई इसे निष्पादित करना चाहते हैं? ? ?',
    //       汇总: 'सारांश',
    //     },
    //     wof_order_detail_comp: {
    //       下单开始日期: 'ऑर्डर प्रारंभ तिथि',
    //       下单结束日期: 'ऑर्डर की अंतिम तिथि',
    //       核销单号: 'सत्यापन आदेश संख्या',
    //       户号: 'खाता संख्या',
    //       核销商: 'ग्राहक',
    //       下单金额: 'ऑर्डर करने की राशि',
    //       缴费状态: 'भुगतान की स्थिति',
    //       已缴费: 'चुकाया गया',
    //       未缴费: 'भुगतान नहीं',
    //       标记: 'निशान',
    //       正常: 'सामान्य',
    //       补单: 'ऑर्डर भरें',
    //       调整: 'समायोजन',
    //       系统单号: 'सिस्टम ऑर्डर नंबर',
    //       根据条件导出: 'शर्तों के आधार पर निर्यात करें',
    //       下单时间: 'आदेश का समय',
    //       系统单号: 'सिस्टम ऑर्डर नंबर',
    //       核销商信息: 'हामीदार जानकारी',
    //       缴费金额: 'भुगतान राशि',
    //       缴费时间: 'भुगतान का समय',
    //       备注: 'टिप्पणी',
    //     },
    //     wof_credit_logs_comp: {
    //       开始日期: 'आरंभ करने की तिथि',
    //       结束日期: 'अंतिम तिथि',
    //       核销商编号: 'हामीदार संख्या',
    //       类型: 'प्रकार',
    //       管理员增加: 'व्यवस्थापक जोड़ा गया',
    //       代理转移增加: 'एजेंसी स्थानांतरण में वृद्धि',
    //       订单缴费扣除: 'आदेश भुगतान कटौती',
    //       管理员扣除: 'प्रशासक कटौती',
    //       代理转移扣除: 'एजेंट स्थानांतरण कटौती',
    //       返还授信: 'क्रेडिट वापस करें',
    //       核销单号: 'सत्यापन आदेश संख्या',
    //       时间: 'समय',
    //       核销编号: 'बट्टे खाते में डालने की संख्या',
    //       核销名称: 'बट्टे खाते में डालने वाला नाम',
    //       变更前: 'बदलाव से पहले',
    //       变更后: 'बदलाव के बाद',
    //       变更额: 'राशि बदलें',
    //       备注: 'टिप्पणी',
    //       核销单号: 'सत्यापन आदेश संख्या',
    //       系统: 'प्रणाली',
    //       无: 'कोई नहीं',
    //       辅助单号: 'खाता संख्या/मोबाइल फ़ोन नंबर',
    //     },
    //   },
    //   order: {
    //     wm_upload_lfel: {
    //       核销单号: 'सत्यापन आदेश संख्या',
    //       全部: 'सभी',
    //       正确的: 'सही',
    //       错误的: 'ग़लत',
    //       选择文件上传: 'अपलोड करने के लिए फ़ाइल का चयन करें',
    //       单个录入: 'एकल प्रवेश',
    //       确定提交: 'सबमिशन की पुष्टि करें',
    //       全部清理: 'सभी साफ करें',
    //       下载模板: 'टेम्पलेट डाउनलोड करें',
    //       行号: 'लाइन नंबर',
    //       核销单号: 'सत्यापन आदेश संख्या',
    //       缴费户号: 'बिल भुगतान खाता संख्या',
    //       缴费金额: 'भुगतान राशि',
    //       地区: 'क्षेत्र',
    //       城市: 'शहर',
    //       备注: 'टिप्पणी',
    //       正常: 'सामान्य',
    //       订单编号: 'क्रम संख्या',
    //       订单编号memo: 'आप इसे खाली छोड़ सकते हैं, लेकिन यह अद्वितीय होना चाहिए',
    //       缴费户号: 'भुगतान खाता संख्या:',
    //       缴费金额: 'भुगतान राशि:',
    //       省: 'प्रांत:',
    //       市: 'शहर:',
    //     },
    //     wm_upload_daifu: {
    //       您的单号: 'आपकी ऑर्डर संख्या',
    //       全部: 'सभी',
    //       正确的: 'सही',
    //       错误的: 'ग़लत',
    //       上码: 'एन्कोडिंग',
    //       确定提交: 'सबमिशन की पुष्टि करें',
    //       全部清理: 'सभी साफ करें',
    //       行号: 'लाइन नंबर',
    //       账号: 'खाता',
    //       金额: 'मात्रा',
    //       链接: 'जोड़ना',
    //       备注: 'टिप्पणी',
    //       正常: 'सामान्य',
    //       账号memo: 'खाता: आप केवल पूछताछ की सुविधा के लिए स्टोर का नाम या उत्पाद कोड नाम भर सकते हैं।',
    //       金额memo: 'राशि: 0 से अधिक होनी चाहिए, नीचे दिए गए सभी लिंक इस राशि का उपयोग करते हैं',
    //       链接memo: 'लिंक: प्रति पंक्ति एक, बीच में कोई पंक्ति नहीं टूटती',
    //     },
    //   },
}
