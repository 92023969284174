<!-- changelly -->
<template>
  <div class="">
    <!-- timer -->
    <TimerComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
    <!--pay btn-->
    <div class="pay-alip">
      <!--changelly-->
      <div v-if="orderInfo.payType=='changelly'">
        <a :href="orderInfo.payUrl" class="btn btn-alip" style="width: 70%;">Pay Immediately</a>
      </div>
    </div>
    <!-- Notice -->
    <div class="pannel-simp attention">
      <p class=""><b>Notice:&nbsp;</b><span style="color: red;">Please do not pay beyond the time limit, </span>otherwise it cannot be recovered!!!</p>
      <p class=""><b>Notice:&nbsp;</b><span style="color: red;">Please do not modify the amount, </span>otherwise it cannot be recovered!!!</p>
      <p class=""><b>Notice:&nbsp;</b><span style="color: red;">Please do not change the payment currency type, </span>otherwise it cannot be recovered!!!</p>
      <p class=""><b>Notice:&nbsp;</b><span style="color: red;">Not checked before the next day, </span>cannot be recovered!!!</p>
      <p class=""><b>Notice:&nbsp;</b>Payment successful confirmation time: <span style="color: red;">5 to 10 minutes</span>, After payment, The account has not been received for more than <span style="color: red;">10 minutes,</span> Please contact customer service to check the order!</p>
    </div>
  </div>
</template>

<script>
import TimerComp from "@/views/comp/timer_comp";
export default {
  components: {
    TimerComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
