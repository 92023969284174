<!-- timer -->
<template>
  <div>
    <!-- tips -->
    <div class="tip-msg">
      <p>
        <span style="color:#4CAF50;"><b>نصائح</b></span>:
        <span>يرجى إكمال الدفع قبل نهاية الوقت وسيتم إلغاء الطلب تلقائيًا بعد انتهاء المهلة.</span>
      </p>
      <p v-if="orderInfo.productType=='life_electric'||orderInfo.productType=='phone_bill'">
        <span style="color:#4CAF50;"><b>注意</b></span>：<span style="color: red;">超时支付，修改金额，修改支付方式，重复支付，</span>无法追回!!!
      </p>
    </div>
    <!-- times -->
    <div class="pay-time title">
      <span style="color:blue;">{{payTimeInfo.timePayoutMin}}:{{payTimeInfo.timePayoutSec}}</span>
    </div>
    <div class="pay-time title" v-if="orderInfo.ifDebug">
      <span style="color:red; font-size: 0.85em;">调试模式20秒倒计时</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
