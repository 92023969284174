export default {
    main: {
        首页: '首页',
        订单生成中: '订单生成中',
        请稍候: '请稍候...',
        安全支付: '安全支付',
        订单号: '订单号',
        金额: '金额',
        支付提示: '支付提示',
        请在: '请在',
        秒内: '秒内',
        完成: '完成',
        支付: '支付',
        超时会自动: '超时会自动',
        取消: '取消',
        剩余时间: '剩余时间',
        订单不存在: '订单不存在',
        订单已失效: '订单已失效',
        订单已失效Memo: '请重新下单或者等待到账!',
        订单已关闭: '订单已关闭',
        订单异常: '订单异常',
        发生错误: '发生错误',
        订单生成失败: '订单生成失败',
        暂无可用的订单: '暂无可用的订单',
    }
    //   route: {
    //     dashboard: '首页',
    //     documentation: '文档',
    //     guide: '引导页',
    //     permission: '权限测试页',
    //     rolePermission: '角色权限',
    //     pagePermission: '页面权限',
    //     directivePermission: '指令权限',
    //     icons: '图标',
    //     components: '组件',
    //     tinymce: '富文本编辑器',
    //     markdown: 'Markdown',
    //     jsonEditor: 'JSON 编辑器',
    //     dndList: '列表拖拽',
    //     splitPane: 'Splitpane',
    //     avatarUpload: '头像上传',
    //     dropzone: 'Dropzone',
    //     sticky: 'Sticky',
    //     countTo: 'Count To',
    //     componentMixin: '小组件',
    //     backToTop: '返回顶部',
    //     dragDialog: '拖拽 Dialog',
    //     dragSelect: '拖拽 Select',
    //     dragKanban: '可拖拽看板',
    //     charts: '图表',
    //     keyboardChart: '键盘图表',
    //     lineChart: '折线图',
    //     mixChart: '混合图表',
    //     example: '综合实例',
    //     nested: '路由嵌套',
    //     menu1: '菜单1',
    //     'menu1-1': '菜单 1-1',
    //     'menu1-2': '菜单 1-2',
    //     'menu1-2-1': '菜单 1-2-1',
    //     'menu1-2-2': '菜单 1-2-2',
    //     'menu1-3': '菜单 1-3',
    //     menu2: '菜单 2',
    //     Table: 'Table',
    //     dynamicTable: '动态 Table',
    //     dragTable: '拖拽 Table',
    //     inlineEditTable: 'Table 内编辑',
    //     complexTable: '综合 Table',
    //     tab: 'Tab',
    //     form: '表单',
    //     createArticle: '创建文章',
    //     editArticle: '编辑文章',
    //     articleList: '文章列表',
    //     errorPages: '错误页面',
    //     page401: '401',
    //     page404: '404',
    //     errorLog: '错误日志',
    //     excel: 'Excel',
    //     exportExcel: '导出 Excel',
    //     selectExcel: '导出 已选择项',
    //     mergeHeader: '导出 多级表头',
    //     uploadExcel: '上传 Excel',
    //     zip: 'Zip',
    //     pdf: 'PDF',
    //     exportZip: 'Export Zip',
    //     theme: '换肤',
    //     clipboardDemo: 'Clipboard',
    //     i18n: '国际化',
    //     externalLink: '外链',
    //     profile: '个人中心',
    //     对接信息: '对接信息',
    //     订单管理: '订单管理',
    //     电费订单管理: '电费订单管理',
    //     电费订单上传: '电费订单上传',
    //     代付订单管理: '代付订单管理',
    //     代付订单上传: '代付订单上传',
    //     对账与统计: '对账与统计',
    //     订单综合统计: '订单综合统计',
    //     订单缴费明细: '订单缴费明细',
    //     授信扣款记录: '授信扣款记录',
    //   },
    //   navbar: {
    //     dashboard: '首页',
    //     github: '项目地址',
    //     logOut: '退出登录',
    //     profile: '个人中心',
    //     theme: '换肤',
    //     size: '布局大小',
    //     余额: '余额',
    //     修改密码: '修改密码',
    //     动态码: '动态码',
    //   },
    //   login: {
    //     title: '系统登录',
    //     logIn: '登录',
    //     username: '账号',
    //     password: '密码',
    //     any: '随便填',
    //     thirdparty: '第三方登录',
    //     thirdpartyTips: '本地不能模拟，请结合自己业务进行模拟！！！',
    //     动态码: '动态码',
    //   },
    //   documentation: {
    //     documentation: '文档',
    //     github: 'Github 地址'
    //   },
    //   permission: {
    //     addRole: '新增角色',
    //     editPermission: '编辑权限',
    //     roles: '你的权限',
    //     switchRoles: '切换权限',
    //     tips: '在某些情况下，不适合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它动态渲染 dom 的场景。你只能通过手动设置 v-if 来实现。',
    //     delete: '删除',
    //     confirm: '确定',
    //     cancel: '取消'
    //   },
    //   guide: {
    //     description: '引导页对于一些第一次进入项目的人很有用，你可以简单介绍下项目的功能。本 Demo 是基于',
    //     button: '打开引导'
    //   },
    //   components: {
    //     documentation: '文档',
    //     tinymceTips: '富文本是管理后台一个核心的功能，但同时又是一个有很多坑的地方。在选择富文本的过程中我也走了不少的弯路，市面上常见的富文本都基本用过了，最终权衡了一下选择了Tinymce。更详细的富文本比较和介绍见',
    //     dropzoneTips: '由于我司业务有特殊需求，而且要传七牛 所以没用第三方，选择了自己封装。代码非常的简单，具体代码你可以在这里看到 @/components/Dropzone',
    //     stickyTips: '当页面滚动到预设的位置会吸附在顶部',
    //     backToTopTips1: '页面滚动到指定位置会在右下角出现返回顶部按钮',
    //     backToTopTips2: '可自定义按钮的样式、show/hide、出现的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    //     imageUploadTips: '由于我在使用时它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的话，优先还是使用官方版本。'
    //   },
    //   table: {
    //     dynamicTips1: '固定表头, 按照表头顺序排序',
    //     dynamicTips2: '不固定表头, 按照点击顺序排序',
    //     dragTips1: '默认顺序',
    //     dragTips2: '拖拽后顺序',
    //     title: '标题',
    //     importance: '重要性',
    //     type: '类型',
    //     remark: '点评',
    //     search: '搜索',
    //     add: '添加',
    //     export: '导出',
    //     reviewer: '审核人',
    //     id: '序号',
    //     date: '时间',
    //     author: '作者',
    //     readings: '阅读数',
    //     status: '状态',
    //     actions: '操作',
    //     edit: '编辑',
    //     publish: '发布',
    //     draft: '草稿',
    //     delete: '删除',
    //     cancel: '取 消',
    //     confirm: '确 定',
    //     noData: '无',
    //     yes: '是',
    //     no: '否',
    //     operation: '操作',
    //     input: '请输入',
    //     add: '新增',
    //     inputNumber: '请输入数字',
    //   },
    //   notice: {
    //     alreadyCancel: '已取消',
    //     areYouSure: '你确定？？？',
    //     notice: '提示',
    //     加载memo: '努力加载中，请稍后……',
    //   },
    //   example: {
    //     warning: '创建和编辑页面是不能被 keep-alive 缓存的，因为keep-alive 的 include 目前不支持根据路由来缓存，所以目前都是基于 component name 来进行缓存的。如果你想类似的实现缓存效果，可以使用 localStorage 等浏览器缓存方案。或者不要使用 keep-alive 的 include，直接缓存所有页面。详情见'
    //   },
    //   errorLog: {
    //     tips: '请点击右上角bug小图标',
    //     description: '现在的管理后台基本都是spa的形式了，它增强了用户体验，但同时也会增加页面出问题的可能性，可能一个小小的疏忽就导致整个页面的死锁。好在 Vue 官网提供了一个方法来捕获处理异常，你可以在其中进行错误处理或者异常上报。',
    //     documentation: '文档介绍'
    //   },
    //   excel: {
    //     export: '导出',
    //     selectedExport: '导出已选择项',
    //     placeholder: '请输入文件名(默认excel-list)'
    //   },
    //   zip: {
    //     export: '导出',
    //     placeholder: '请输入文件名(默认file)'
    //   },
    //   pdf: {
    //     tips: '这里使用   window.print() 来实现下载pdf的功能'
    //   },
    //   theme: {
    //     change: '换肤',
    //     documentation: '换肤文档',
    //     tips: 'Tips: 它区别于 navbar 上的 theme-pick, 是两种不同的换肤方法，各自有不同的应用场景，具体请参考文档。'
    //   },
    //   tagsView: {
    //     refresh: '刷新',
    //     close: '关闭',
    //     closeOthers: '关闭其它',
    //     closeAll: '关闭所有',
    //     首页: '首页',
    //   },
    //   settings: {
    //     title: '系统布局配置',
    //     theme: '主题色',
    //     tagsView: '开启 Tags-View',
    //     fixedHeader: '固定 Header',
    //     sidebarLogo: '侧边栏 Logo'
    //   },
    //   //核销后台
    //   wmerch: {
    //     apis: {
    //       对接编号: '对接编号',
    //       签名密钥: '签名密钥',
    //       网关地址: '网关地址',
    //       上单接口: '上单接口',
    //       查单接口: '查单接口',
    //       撤单接口: '撤单接口',
    //       异步回调IP: '异步回调IP',
    //       在线文档: '在线文档',
    //       对接签名文档: '对接签名文档',
    //       话费上单接口文档: '话费上单接口文档',
    //       电费上单接口文档: '电费上单接口文档',
    //       查单接口文档: '查单接口文档',
    //       撤单接口文档: '撤单接口文档',
    //       异步回调文档: '异步回调文档',
    //       php代码例子: 'php代码例子',
    //       IP白名单设置: 'IP白名单设置',
    //       是否启用: '是否启用',
    //       填写IP地址: '填写IP地址',
    //       填写IP地址Memo: '多个IP用空格或换行区分',
    //       其他设置: '其他设置',
    //       订单超时时间: '订单超时时间',
    //       订单超时时间Memo: '仅限通过后台手动上单的超时时间，接口上单不影响',
    //       部分支付是否回调: '部分支付是否回调',
    //       部分支付是否回调Memo1: '注意：此功能一单开启，请妥善处理回调逻辑！！！',
    //       部分支付是否回调Memo2: '不要收到回调信息就吧订单当做撤单或结束状态处理',
    //       需要回调: '需要回调',
    //       不用回调: '不用回调',
    //       //
    //       请输入: '请输入',
    //       秒: '秒',
    //       更新: '更新',
    //       重置: '重置',
    //       启用: '启用',
    //       禁用: '禁用',
    //       保存: '保存',
    //     },
    //   },
    //   trip: {
    //     wof_order_comp: {
    //       核销单号: '核销单号',
    //       户号: '户号',
    //       油卡卡号: '油卡卡号',
    //       核销商编号: '核销商编号',
    //       核销商名称: '核销商名称',
    //       金额: '金额',
    //       地区: '地区',
    //       系统单号: '系统单号',
    //       户号错误: '户号错误',
    //       错误: '错误',
    //       正常: '正常',
    //       授信状态: '授信状态',
    //       授信额充足: '授信额充足',
    //       授信额已预警: '授信额已预警',
    //       派单状态: '派单状态',
    //       开启派单: '开启派单',
    //       停止派单: '停止派单',
    //       '创建日期-起': '创建日期-起',
    //       '创建日期-终': '创建日期-终',
    //       '超时日期-起': '超时日期-起',
    //       '超时日期-终': '超时日期-终',
    //       '回调日期-起': '回调日期-起',
    //       '回调日期-终': '回调日期-终',
    //       '结束日期-起': '结束日期-起',
    //       '结束日期-终': '结束日期-终',
    //       全部: '全部',
    //       待检测: '待检测',
    //       待使用: '待使用',
    //       取单中: '取单中',
    //       待缴费: '待缴费',
    //       部分付: '部分付',
    //       已缴费: '已缴费',
    //       未缴费: '未缴费',
    //       异常的: '异常的',
    //       已取消: '已取消',
    //       未回调: '未回调',
    //       未结束: '未结束',
    //       已结束: '已结束',
    //       根据条件统计: '根据条件统计',
    //       实时库存统计: '实时库存统计',
    //       根据条件回调: '根据条件回调',
    //       根据条件撤单: '根据条件撤单',
    //       根据条件导出: '根据条件导出',
    //       系统单号: '系统单号',
    //       客户名称: '客户名称',
    //       核销商编号: '核销商编号',
    //       核销商名称: '核销商名称',
    //       手机: '手机',
    //       运营商: '运营商',
    //       户号: '户号',
    //       类型: '类型',
    //       地区: '地区',
    //       油卡卡号: '油卡卡号',
    //       代付账户: '代付账户',
    //       代付地址: '代付地址',
    //       金额: '金额',
    //       已充: '已充',
    //       最低: '最低',
    //       金额状态: '金额状态',
    //       查询金额: '查询金额',
    //       拆单: '拆单',
    //       已拆: '已拆',
    //       未拆: '未拆',
    //       本次应充: '本次应充',
    //       拉单次数: '拉',
    //       缴费: '缴费',
    //       零充值: '零充值',
    //       已全充: '已全充',
    //       部分充: '部分充',
    //       已超充: '已超充',
    //       其他: '其他',
    //       状态: '状态',
    //       回调: '回调',
    //       已回调: '已回调',
    //       不需要: '不需要',
    //       未回调: '未回调',
    //       创建时间: '创建时间',
    //       超时时间: '超时时间',
    //       回调时间: '回调时间',
    //       结束时间: '结束时间',
    //       备注: '备注',
    //       错误: '错误',
    //       户号: '户号',
    //       正常: '正常',
    //       JD黑户错误: 'JD黑户错误',
    //       是否改单: '改',
    //       撤单: '撤单',
    //       强制撤单: '强制撤单',
    //       强制回调: '强制回调',
    //       异常恢复: '异常恢复',
    //       详细信息header: '详',
    //       详情: '详情',
    //       详细信息: '详细信息',
    //       修改时间: '修改时间',
    //       户号金额类型: '户号金额类型',
    //       GW公司ID: 'GW公司ID',
    //       GW公司名: 'GW公司名',
    //       本次已充: '本次已充',
    //       金额变动前: '金额变动前',
    //       回调地址: '回调地址',
    //       上次缴费时间: '上次缴费时间',
    //       上次取单时间: '上次取单时间',
    //       上次查单时间: '上次查单时间',
    //       异常时间: '异常时间',
    //       上次使用时间: '上次使用时间',
    //       检测错误数: '检测错误数',
    //       查余额次数: '查余额次数',
    //       绑定支付单: '绑定支付单',
    //       查单: '查单',
    //       调整: '调整',
    //       订单金额: '订单金额',
    //       已付金额: '已付金额',
    //       拆单应付: '拆单应付',
    //       应付金额: '应付金额',
    //       本次已付: '本次已付',
    //       修改已付缴费: '修改已付缴费',
    //       备注: '备注',
    //       高级验证: '高级验证',
    //       时间: '时间',
    //       账户: '账户',
    //       最小缴费: '最小缴费',
    //       金额: '金额',
    //       有效金额: '有效金额',
    //       立即获取最新的: '立即获取最新的',
    //       实时统计: '实时统计',
    //       包含无授信额的核销: '包含无授信额的核销',
    //       包含停止派单的核销: '包含停止派单的核销',
    //       单: '单', // XXX单，有多少订单的意思
    //       元: '元', // XXX元，人民币多少元的意思，要翻译成印度货币单位
    //       可用核销单: '可用核销单：',
    //       核销单总额: '核销单总额：',
    //       待缴库存: '待缴库存：',
    //       挂起库存: '挂起库存：',
    //       可用库存: '可用库存',
    //       刷新统计: '刷新统计',
    //       请输入高级验证: '请输入高级验证',
    //       强制撤单notice: "请输入高级密码（二级密码或动态密码）",
    //       强制撤单title: "撤销后将无法回退，请注意资金是否准确",
    //       条件撤单notice: '请输入高级密码（二级密码或动态密码）',
    //       条件撤单title: '根据条件撤单，可能时间较长，你确定？',
    //       核销条件撤单notice: '可能时间较长，撤单后请再次确认是否还有未撤单的，你确定？',
    //       核销条件撤单title: '根据条件撤单',
    //       条件回调notice: '请输入高级密码（二级密码或动态密码）',
    //       条件回调title: '根据条件强制回调，可能时间较长，你确定？',
    //       条件回调msg: '后台正在逐个回调，请等待一段时间',
    //       根据条件实时统计数据如下: '根据条件实时统计数据如下',
    //       补单title: '注意：非特殊情况不要修改核销单；修改核销单不会同时修改商户订单；如果需要修改商户订单请在商户订单中补单或修改',
    //       查单: '查单',
    //       编辑招聘者: '编辑招聘者',
    //     },
    //     wof_order_statis_comp: {
    //       起始日期: '起始日期（默认当天）',
    //       结束日期: '结束日期（默认到现在）',
    //       核销商: '核销商',
    //       统计日期: '统计日期',
    //       核销商名: '核销商名',
    //       单笔金额: '单笔金额',
    //       标记信息: '标记信息',
    //       合计: '合计',
    //       全部: '全部',
    //       统计日期: '统计日期',
    //       核销商名: '核销商名',
    //       上级核销: '上级核销',
    //       单笔金额: '单笔金额',
    //       标记信息: '标记信息',
    //       上单数量: '上单数量',
    //       成功笔数: '成功笔数',
    //       缴费总额: '缴费总额',
    //       重新统计这天: '重新统计这天',
    //       重新统计这天notice: '当数据量过大时，可能会需要一些时间，你确定执行吗？？？',
    //       汇总: '汇总',
    //     },
    //     wof_order_detail_comp: {
    //       下单开始日期: '下单开始日期',
    //       下单结束日期: '下单结束日期',
    //       核销单号: '核销单号',
    //       户号: '户号',
    //       核销商: '核销商',
    //       下单金额: '下单金额',
    //       缴费状态: '缴费状态',
    //       已缴费: '已缴费',
    //       未缴费: '未缴费',
    //       标记: '标记',
    //       正常: '正常',
    //       补单: '补单',
    //       调整: '调整',
    //       系统单号: '系统单号',
    //       根据条件导出: '根据条件导出',
    //       下单时间: '下单时间',
    //       系统单号: '系统单号',
    //       核销商信息: '核销商信息',
    //       缴费金额: '缴费金额',
    //       缴费时间: '缴费时间',
    //       备注: '备注',
    //     },
    //     wof_credit_logs_comp: {
    //       开始日期: '开始日期',
    //       结束日期: '结束日期',
    //       核销商编号: '核销商编号',
    //       类型: '类型',
    //       管理员增加: '管理员增加',
    //       代理转移增加: '代理转移增加',
    //       订单缴费扣除: '订单缴费扣除',
    //       管理员扣除: '管理员扣除',
    //       代理转移扣除: '代理转移扣除',
    //       返还授信: '返还授信',
    //       核销单号: '核销单号',
    //       时间: '时间',
    //       核销编号: '核销编号',
    //       核销名称: '核销名称',
    //       变更前: '变更前',
    //       变更后: '变更后',
    //       变更额: '变更额',
    //       备注: '备注',
    //       核销单号: '核销单号',
    //       系统: '系统',
    //       无: '无',
    //       辅助单号: '户号/手机号',
    //     },
    //   },
    //   order: {
    //     wm_upload_lfel: {
    //       核销单号: '核销单号',
    //       全部: '全部',
    //       正确的: '正确的',
    //       错误的: '错误的',
    //       选择文件上传: '选择文件上传',
    //       单个录入: '单个录入',
    //       确定提交: '确定提交',
    //       全部清理: '全部清理',
    //       下载模板: '下载模板',
    //       行号: '行号',
    //       核销单号: '核销单号',
    //       缴费户号: '缴费户号',
    //       缴费金额: '缴费金额',
    //       地区: '地区',
    //       城市: '城市',
    //       备注: '备注',
    //       正常: '正常',
    //       订单编号: '订单编号',
    //       订单编号memo: '可以不填，但必须唯一',
    //       缴费户号: '缴费户号：',
    //       缴费金额: '缴费金额：',
    //       省: '省：',
    //       市: '市：',
    //     },
    //     wm_upload_daifu: {
    //       您的单号: '您的单号',
    //       全部: '全部',
    //       正确的: '正确的',
    //       错误的: '错误的',
    //       上码: '上码',
    //       确定提交: '确定提交',
    //       全部清理: '全部清理',
    //       行号: '行号',
    //       账号: '账号',
    //       金额: '金额',
    //       链接: '链接',
    //       备注: '备注',
    //       正常: '正常',
    //       账号memo: '账号：可填写店铺名称或产码小号名称，只用于方便查询',
    //       金额memo: '金额：必须大于0，下面所有链接都使用该金额',
    //       链接memo: '链接：一行一个，中间不能换行',
    //     },
    //   },
}
