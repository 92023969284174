<!-- 支付链接 -->
<template>
  <div class="">
    <div class="pannel" style="">
      <table class="details" style="">
        <tr>
          <td>支付链接</td>
          <td>
            <span v-if="cptyDaifuUrlIf" style="color: green;">已复制</span>
            <button @click="copyDaifuUrl" class="btn copy">复制链接</button>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align:center;">
            <div style="font-size: 0.75em;">
              <p style="color: red;">如果复制不到链接，请手动复制下面框内链接</p>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="2" style="text-align:center;">
            <textarea style="border:1;background-color:rgba(241,241,241,.98);width:94%;height:100px;padding:5px;" readonly v-model="daifuUrl"></textarea>
          </td>
        </tr>
      </table>
    </div>
    <!-- 倒计时 -->
    <TimerComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
    <!--代付链接-->
    <div class="pay-alip">
      <div v-if="orderInfo.payType=='alipay'">
        <a :href="orderInfo.daifuUrl" class="btn btn-alip" style="width: 70%;">立即跳转（需下载拼多多）</a>
      </div>
      <div v-if="orderInfo.payType=='weixin'">
        <a :href="orderInfo.daifuUrl" class="btn btn-weix" style="width: 70%;">立即跳转（需下载拼多多）</a>
      </div>
    </div>
    <!-- 付款流程 -->
    <div class="pannel">
      <div class="pay-flow" v-if="orderInfo.payType=='weixin'">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、打开微信聊天</p>
        <p class="no">2、随便找个好友（或自己）的聊天界面</p>
        <p class="no">3、发送链接给他，并点击链接进入</p>
      </div>
    </div>
  </div>
</template>

<script>
import TimerComp from "@/views/comp/timer_comp";
export default {
  components: {
    TimerComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 复制相关
      cptyDaifuUrlIf: false,
      //
      daifuUrl: this.orderInfo.daifuUrl,
    };
  },
  computed: {},
  created() {},
  methods: {
    // 复制代付链接
    copyDaifuUrl: function () {
      this.$parent.copySome(
        this.orderInfo.daifuUrl,
        2,
        () => {
          this.cptyDaifuUrlIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.copyErr();
        }
      );
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
