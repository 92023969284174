import axios from 'axios'
import qs from 'qs'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, //  
    timeout: 60000 // request timeout
});


// request interceptor
service.interceptors.request.use(
    config => {
        // 设置Content-Type
        config.headers['Content-Type'] = config.headers['Content-Type'] || 'application/x-www-form-urlencoded'
        // 数据转换
        config.transformRequest = [
            data => {
                var contentType = config.headers['Content-Type']
                if (contentType === 'application/x-www-form-urlencoded') {
                    data = qs.stringify(data, { indices: false });
                } else if (contentType === 'application/json') {
                    data = JSON.stringify(data);
                }
                return data;
            }
        ]
        //
        return config
    },
    error => {
        console.log(error);
        return Promise.reject(error);
    }
);

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data;
        //如果是文件流，直接返回
        if (response.headers["content-type"] == "application/octet-stream") {
            return res;
        }
        // 发生错误
        if (res.errcode != 0) {
            return Promise.reject(res);
        }
        // 正确
        // 返回
        return res;
    },
    error => {
        console.error(error);
        return Promise.reject({
            "errcode": 100,
            "errmsg": "发生错误"
        });
    }
);

export default service;