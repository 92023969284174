<template>
  <div id="app" class="container">

    <!--拷贝-->
    <div style="left: -100; top: -100; position: absolute; z-index: -1; color: rgba(0,0,0,0);  background-color: rgba(0,0,0,0);">
      <textarea id="copyObj" style="width: 1px; height: 1px;" readonly="true"></textarea>
      <canvas ref="canvas" style="display: none;"></canvas>
    </div>

    <!-- 提示信息 -->
    <div v-if="showPanel==1" class="title">
      <h1>{{showMsg}}</h1>
    </div>

    <!-- 等待拉单 -->
    <div v-if="showPanel==2" class="title">
      <div>
        <h1>{{ $t("main['订单生成中']") }}</h1>
      </div>
      <div>
        <h2>{{ $t("main['请稍候']") }}</h2>
      </div>
      <div>
        <h1>{{getPayTimedownNo}}</h1>
      </div>
    </div>

    <!-- 支付信息 -->
    <div v-if="showPanel==3">
      <!-- 旗舰  -->
      <BasePan1 :order-info="orderInfo" v-if="payPanel=='phone_bill'">
        <PhoneBill :order-info="orderInfo" :pay-time-info="payTimeInfo" />
      </BasePan1>
      <!-- 生活电费  -->
      <BasePan1 :order-info="orderInfo" v-if="payPanel=='life_electric'">
        <LifeElectricComp :order-info="orderInfo" :pay-time-info="payTimeInfo" :alip-info="alipInfo" />
      </BasePan1>
      <!-- 支付链接  -->
      <BasePan1 :order-info="orderInfo" v-if="payPanel=='pay_url'">
        <PayUrlComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
      </BasePan1>
      <!-- 代付  -->
      <BasePan1 :order-info="orderInfo" v-if="payPanel=='daifu'">
        <DaifuComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
      </BasePan1>
      <!-- Changelly  -->
      <BasePan1 :order-info="orderInfo" v-if="payPanel=='changelly'">
        <ProductChangelly :order-info="orderInfo" :pay-time-info="payTimeInfo" />
      </BasePan1>
      <!-- BankCard  -->
      <ProductBankcard :order-info="orderInfo" :pay-time-info="payTimeInfo" :qcode="bkQr" v-if="payPanel=='bankcard'" />
      <!-- BankCard  -->
      <ProductVodafone :order-info="orderInfo" :pay-time-info="payTimeInfo" v-if="payPanel=='vodafone'" />
    </div>
    <!-- 已失效 -->
    <div v-if="showPanel==4" class="title">
      <div>
        <h1>{{$t("main['订单已失效']")}}</h1>
      </div>
      <div>
        <h2>{{$t("main['订单已失效Memo']")}}</h2>
      </div>
    </div>
  </div>
</template>

<script>
// import FingerprintJS from "@/utils/fingerprintjs.v4.js"; //暂时去掉
import * as apis from "@/apis/apis";
import QRCode from "qrcode";

import BasePan1 from "@/views/comp/sub/base_pan1";

import PhoneBill from "@/views/comp/phone_bill";
import LifeElectricComp from "@/views/comp/life_electric_comp";
import PayUrlComp from "@/views/comp/pay_url_comp";
import DaifuComp from "@/views/comp/daifu_comp";
import ProductChangelly from "@/views/comp/product_changelly";
import ProductBankcard from "@/views/comp/product_bankcard";
import ProductVodafone from "@/views/comp/product_vodafone";

export default {
  name: "App",
  components: {
    BasePan1,
    PhoneBill,
    LifeElectricComp,
    PayUrlComp,
    DaifuComp,
    ProductChangelly,
    ProductBankcard,
    ProductVodafone,
  },
  data() {
    return {
      // URL参数
      id: "",
      debug: false,
      // 指纹
      visitorId: "",
      // 显示的面板
      showPanel: 1,
      showMsg: "Loading...",
      // 拉单倒计时
      getPayTimedownNo: 60, //
      getPayInfoOver: false, // 倒计时结束
      // 面板组件
      payPanel: null,
      // 订单信息
      orderInfo: {},
      //支付倒计时
      payTimeInfo: {
        timePayoutMin: "-", //支付时间提示
        timePayoutSec: "--", //支付时间提示
      },
      //支付宝信息
      alipInfo: {
        alipAuto: false, // 是否唤醒方式
        alipUrl: "", //  唤醒地址
        alipQr: false, //是否使用二维码
        alipQrURL: "", // 二维码图片地址
      },
      //
      bkQr: "",
      // 其他
      version: "v0.1",
      showLoad: 0,
      msgtxt: "",
    };
  },
  created() {
    this.$i18n.locale = "en";

    // 获取订单号
    const params = new URLSearchParams(window.location.search);
    const id = params.get("id");
    if (id == null || id == "") {
      this.showPanel = 1;
      this.showMsg = this.$t("main['首页']");
      return;
    }
    this.id = id;
    // 获取debug
    let debug = params.get("debug");
    if (debug != null && debug == "true") {
      this.debug = true;
    }
    // 加载指纹
    // const fpPromise = FingerprintJS.load();
    // fpPromise
    //   .then((fp) => fp.get())
    //   .then((result) => {
    //     this.visitorId = result.visitorId;
    //     this.createPayInfo();
    //   })
    //   // eslint-disable-next-line
    //   .catch((error) => {
    //     this.createPayInfo();
    //   });
    //
    // 请求订单
    this.createPayInfo();
  },
  methods: {
    // 开始拉单
    createPayInfo() {
      // 提交已访问
      apis
        .createPayInfo({
          id: this.id,
          visitorId: this.visitorId,
          debug: this.debug,
        })
        .then((res) => {
          // 强制设置语言
          if (res.datas.language) {
            this.$i18n.locale = res.datas.language;
          }
          // 显示面板
          this.showPanel = 2;
          //拉单倒计时
          this.getPayTimedown();
          // 定时获取拉单信息
          this.getPayInfo();
        })
        .catch((err) => {
          this.showPanel = 1;
          this.showMsg = this.$t(`main['${err.errmsg}']`);
        });
    },
    // 拉单倒计时
    getPayTimedown() {
      let $that = this;
      //开始循环查询
      let timer = setInterval(function () {
        // 超过时间
        $that.getPayTimedownNo--;
        if ($that.getPayTimedownNo <= 0) {
          clearInterval(timer);
          //标记失败
          $that.showPanel = 1;
          $that.showMsg = $that.$t(`main['订单生成失败']`);
          return;
        }
        // 强制停止
        if ($that.getPayInfoOver) {
          clearInterval(timer);
          return;
        }
      }, 1000);
    },
    //获取支付信息
    getPayInfo() {
      apis
        .getPayInfo({
          id: this.id,
          debug: this.debug,
        })
        .then((res) => {
          if (res.datas == null) {
            //是否继续
            if (this.getPayTimedownNo <= 0) {
              return;
            }
            // 继续下一次
            setTimeout(() => {
              this.getPayInfo();
            }, 1000);
          } else {
            this.getPayInfoOver = true; //标记结束
            // 设置主信息
            this.orderInfo = res.datas;
            // 设置面板
            this.payPanel = res.datas.payPanel;
            // 不同产品类型的处理
            let productType = res.datas.productType;
            if (productType == "phone_bill") {
              // 暂无设置
            } else if (productType == "life_electric") {
              //
              this.powerCompName = res.datas.powerCompName;
              this.prov = res.datas.prov;
              this.city = res.datas.city;
              this.doorNum = res.datas.doorNum;
              //
              this.alipInfo.alipAuto = res.datas.alipAuto;
              this.alipInfo.alipUrl = res.datas.alipUrl;
              this.alipInfo.alipQr = res.datas.alipQr;
              // 创建二维码图片
              if (this.alipInfo.alipAuto && this.alipInfo.alipQr) {
                QRCode.toDataURL(
                  this.alipInfo.alipUrl,
                  { errorCorrectionLevel: "L", margin: 2, width: 500 },
                  (err, url) => {
                    if (err) {
                      return;
                    }
                    this.alipInfo.alipQrURL = url;
                  }
                );
              }
            } else if (productType == "youka" || productType == "jd_dianfei") {
              //window.location.href = payURL;
              // 暂无设置
            } else if (productType == "pdd_daifu") {
              // 暂无设置
            } else if (productType == "bankcard") {
              // 创建二维码图片
              if (this.orderInfo.qrcode) {
                // QRCode.toDataURL(
                //   this.orderInfo.qrcode,
                //   { errorCorrectionLevel: "L", margin: 2, width: 500 },
                //   (err, url) => {
                //     if (err) {
                //       console.log(err);
                //       return;
                //     }
                //     this.orderInfo.bcQr = url;
                //   }
                // );
                const size = 500;
                const canvas = this.$refs.canvas;
                const ctx = canvas.getContext("2d");
                const logoImage = new Image();
                // 生成二维码
                let $that = this;
                QRCode.toCanvas(canvas, this.orderInfo.qrcode, { width: size })
                  .then(() => {
                    logoImage.src = "/imgs/bc/ACB.png"; // 替换为你的logo图片地址
                    logoImage.onload = () => {
                      // 绘制logo
                      ctx.drawImage(
                        logoImage,
                        size * 0.4,
                        size * 0.4,
                        size * 0.2,
                        size * 0.2
                      );
                      // 导出合并后的图片
                      $that.bkQr = canvas.toDataURL("image/png");
                    };
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              }
            }
            //不同面板的处理
            if (this.payPanel == "redirect") {
              //重定向的
              let payUrl = res.datas.payUrl;
              if (payUrl == null) {
                this.showPanel = 1;
                this.showMsg = this.$t(`main['发生错误']`);
                return;
              }
              window.location.href = payUrl;
              return;
            } else {
              // 暂无设置
            }
            //切换面板
            this.showPanel = 3;
            this.waitPayTimedown();
          }
        })
        .catch((err) => {
          this.getPayInfoOver = true; //标记结束
          this.showPanel = 1;
          this.showMsg = this.$t(`main['${err.errmsg}']`);
        });
    },
    // 待支付倒计时
    waitPayTimedown() {
      let $that = this;
      let endTime = this.orderInfo.timePayout;
      // 计算剩余的分秒
      // eslint-disable-next-line
      let aaa = function (timer) {
        let leftTime = endTime - new Date().getTime(); //距离结束时间的毫秒数
        if (leftTime < 0) {
          //$that.showPanel = 4; //时区问题导致这里会遮住付款，需要吧从浏览器取得时间的地方全部去掉
          return false; //停止执行
        } else {
          var leftm = Math.floor(leftTime / (1000 * 60)); //计算分钟数
          var lefts = Math.floor((leftTime / 1000) % 60); //计算秒数
          $that.payTimeInfo.timePayoutMin = leftm.toString();
          $that.payTimeInfo.timePayoutSec = lefts.toString();
          if ($that.payTimeInfo.timePayoutSec.length == 1) {
            $that.payTimeInfo.timePayoutSec =
              "0" + $that.payTimeInfo.timePayoutSec;
          }
          return true; //继续执行
        }
      };
      // 先计算一次
      aaa();
      // 计时
      let num = 0; // 倒计时秒数
      let timer = setInterval(function () {
        num++;
        var subTime = false; //是否提交停留时间
        // 是否继续执行
        var continueFn = aaa();
        if (!continueFn) {
          subTime = true; //如果结束了也提交停留时间
          clearInterval(timer); //结束
        }
        // 5秒一次提交一次停留时间
        var nMo = num % 5;
        if (nMo == 0) {
          subTime = true;
        }
        //提交停留时间
        if (subTime) {
          apis
            .subTimes({
              id: $that.id,
            })
            // eslint-disable-next-line
            .then((res) => {})
            // eslint-disable-next-line
            .catch((err) => {});
        }
      }, 1000);
    },
    // 复制
    copySome: function (text, type, cb, er) {
      if (navigator.clipboard && window.isSecureContext) {
        console.log("copy1");
        navigator.clipboard.writeText(text).then(cb).catch(er);
      } else {
        console.log("copy2");
        try {
          var textArea = document.getElementById("copyObj");
          textArea.value = text;
          //textArea.focus();
          textArea.select();
          textArea.setSelectionRange(0, 9999);
          document.execCommand("copy");
          textArea.setSelectionRange(0, 0);
          //成功
          cb();
        } catch (err) {
          er(err);
        }
      }
      this.copySub(type);
    },
    // 提交复制
    copySub: function (type) {
      apis
        .subCopy({
          id: this.id,
          type: type,
        })
        // eslint-disable-next-line
        .then((res) => {})
        // eslint-disable-next-line
        .catch((err) => {});
    },
    // 提交复制出错
    copyErr: function () {
      apis
        .subCopyErr({
          id: this.id,
        })
        // eslint-disable-next-line
        .then((res) => {})
        // eslint-disable-next-line
        .catch((err) => {});
    },
  },
};
</script>

<style>
.title {
  text-align: center;
}

.pannel {
  background-color: #f5f5f5;
  margin-bottom: 10px;
}

.tip-msg {
  padding: 0 10px;
  margin-bottom: 10px;
  font-size: 0.75em;
}

.pay-time {
  padding: 0 10px;
  margin-bottom: 10px;
}

.pay-alip {
  text-align: center;
  margin-bottom: 10px;
}

.pay-flow {
  padding: 10px;
}
.pay-flow p.no {
  font-size: 0.85em;
  color: rgb(255, 151, 106);
  padding: 2px 0;
}

.pannel-simp {
  padding: 10px;
}
.attention {
  font-size: 0.85em;
}

table.details {
  width: 100%;
}
table.details tr td:nth-child(1) {
  padding: 5px 10px;
  text-align: left;
}
table.details tr td:nth-child(2) {
  padding-right: 10px;
  text-align: right;
}

button.btn,
a.btn {
  background-color: #4caf50;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 3px;
}
a.btn {
  padding: 15px 32px;
}
button.copy {
  background-color: rgb(255, 151, 106);
  padding: 6px 10px;
  margin-left: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 0.75em !important;
}
button.switch {
  padding: 4px 8px;
}
a.btn-alip {
  background-color: rgb(22, 119, 255);
}
a.btn-weix {
  background-color: #4caf50;
}
button.video {
  border: 2px solid;
  border-color: rgb(22, 119, 255);
  color: rgb(22, 119, 255);
  background-color: white;
  width: 100%;
  padding: 6px 10px;
}

.bankcard-amount {
  margin-bottom: 0.95em;
  font-size: 0.95em;
  color: #a30f0f;
}
.bankcard-owner {
  margin-top: 0.95em;
  margin-bottom: 0.95em;
  font-size: 0.75em;
}
.bankcard-account {
  margin-bottom: 0.95em;
  font-size: 0.9em;
}
button.bankcard-copy {
  background-color: rgb(22, 119, 255);
  padding: 6px 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 0.75em !important;
  width: 50%;
}
a.bankcard-down {
  border: 2px solid;
  border-color: rgb(22, 119, 255);
  padding: 3px 0;
  color: rgb(22, 119, 255);
  background-color: white;
  font-size: 0.75em !important;
  width: 50%;
}

.vdf-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

.vdf-modal-content {
  background-color: #fff;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.vdf-phone-container {
  display: flex;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.vdf-phone-number {
  text-align: center;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #ccc !important;
  padding: 10px 5px !important;
  font-size: 28px;
  font-weight: bold;
}

.vdf-modal input {
  width: 90%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: auto;
}
</style>
