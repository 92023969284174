<!-- 生活缴费 -->
<template>
  <div class="">
    <!-- 复制区域 -->
    <div class="pannel" style="">
      <table class="details" style="">
        <tr v-if="orderInfo.payType=='weixin'">
          <td>缴费单位：</td>
          <td>
            <span style="color: blue;">{{orderInfo.prov}}电费</span>
          </td>
        </tr>
        <tr v-if="orderInfo.payType=='alipay' && !orderInfo.alipAuto">
          <td>缴费单位：</td>
          <td>
            <span style="color: blue;">{{orderInfo.powerCompName}}</span>
          </td>
        </tr>
        <tr v-if="!orderInfo.alipAuto">
          <td>地区</td>
          <td>
            <span v-if="copyCityIf" style="color: green;">{{orderInfo.city}}</span>
            <span v-else style="color: blue;">{{orderInfo.city}}</span>
            <button v-on:click="copyCity" class="btn copy">复制地区</button>
          </td>
        </tr>
        <tr v-if="!orderInfo.alipAuto">
          <td>缴费户号</td>
          <td>
            <span v-if="copyDoorIf" style="color: green;">{{orderInfo.doorNum}}</span>
            <span v-else style="color: blue;">{{orderInfo.doorNum}}</span>
            <button v-on:click="copyDoor" class="btn copy">复制户号</button>
          </td>
        </tr>
        <tr>
          <td>金额</td>
          <td>
            <span v-if="cptyAmountIf" style="color: green;"><b>{{orderInfo.amount}}</b></span>
            <span v-else style="color: red;"><b>{{orderInfo.amount}}</b></span>
            <button @click="copyAmount" class="btn copy">复制金额</button>
          </td>
        </tr>
      </table>
    </div>
    <!-- 倒计时 -->
    <TimerComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />
    <!--支付宝唤醒按钮-->
    <div class="pay-alip" v-if="orderInfo.payType=='alipay' && alipInfo.alipAuto">
      <!--支付宝唤醒按钮-->
      <div v-if="!alipInfo.alipQr">
        <a :href="alipInfo.alipUrl" class="btn btn-alip" style="width: 70%;">立即支付</a>
      </div>
      <!--二维码-->
      <div v-else>
        <img :src="alipInfo.alipQrURL" style="margin: 0 auto;width: 60%;" />
      </div>
    </div>
    <!-- 付款流程 -->
    <div class="pannel">
      <div class="pay-flow" v-if="orderInfo.payType=='alipay' && !alipInfo.alipAuto">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、打开支付宝APP</p>
        <p class="no">2、搜索“生活缴费”小程序</p>
        <p class="no">3、选择“电费”模块，填写上面相应的地区，户号，金额</p>
      </div>
      <div class="pay-flow" v-if="orderInfo.payType=='alipay' && alipInfo.alipAuto && !alipInfo.alipQr">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、请点击立即支付按钮</p>
        <p class="no">2、填写上面相应的金额</p>
        <p class="no">3、无法打开支付宝？供电所错误？</p>
        <button class="btn switch" @click="alipAuto=false">点此换成手动复制</button>
      </div>
      <div class="pay-flow" v-if="orderInfo.payType=='alipay' && alipInfo.alipAuto && orderInfo.alipQr">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、长按保存二维码图片</p>
        <p class="no">2、打开支付宝APP，点击右上角扫一扫</p>
        <p class="no">3、相册选择付款二维码</p>
        <p class="no">4、填写上面相应的金额并支付</p>
      </div>
      <div class="pay-flow" v-if="orderInfo.payType=='weixin'">
        <p style="color: #4CAF50;"><b>付款流程：</b></p>
        <p class="no">1、打开微信APP</p>
        <p class="no">2、搜索“生活缴费”小程序</p>
        <p class="no">3、选择“电费”模块，填写上面相应的地区，户号，金额</p>
      </div>
    </div>
    <!--演示视频-->
    <div class="pannel-simp title" v-if="orderInfo.productType=='life_electric'">
      <div class="">
        <button class="btn video" @click="showVideo=!showVideo">显示支付流程视频</button>
      </div>
      <div style="text-align:center;" v-if="orderInfo.payType=='alipay' && showVideo">
        <video id="video1" width="90%;" style="margin-top:5px;" muted controls preload="auto" autoplay="autoplay" data-setup="{'autoplay':true}" loop>
          <source src="/imgs/zfb01.mp4" type="video/mp4" v-if="orderInfo.alipAuto" />
          <source src="/imgs/zfb02.mp4" type="video/mp4" v-else />
        </video>
      </div>
      <div style="text-align:center;" v-if="orderInfo.payType=='weixin' && showVideo">
        <video id="video1" width="90%;" style="margin-top:5px;" muted controls preload="auto" autoplay="autoplay" data-setup="{'autoplay':true}" loop>
          <source src="/imgs/wx01.mp4" type="video/mp4" />
        </video>
      </div>
    </div>

    <!-- 警告事项 -->
    <div class="pannel-simp attention">
      <p class="">注意：<span style="color: red;">超时支付,修改金额,修改支付方式,重复支付,</span>无法追回!!!</p>
      <p class="">注意：<span style="color: red;">次日前未查,</span>无法追回!!!</p>
      <p class="">注意：支付成功确认时间<span style="color: red;">5-10分钟</span>,付款后<span style="color: red;">超过10分钟</span>还未到账,请联系客服查单补单!</p>
    </div>
    <div class="pannel-simp attention" style="margin-bottom: 100px;"></div>

  </div>
</template>

<script>
import TimerComp from "@/views/comp/timer_comp";

export default {
  components: {
    TimerComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    alipInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // 复制相关
      copyCityIf: false,
      copyDoorIf: false,
      cptyAmountIf: false,
      //
      showVideo: false, //显示视频
    };
  },
  computed: {},
  created() {},
  methods: {
    // 复制城市
    copyCity: function () {
      this.$parent.$parent.copySome(
        this.orderInfo.city,
        3,
        () => {
          this.copyCityIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.$parent.copyErr();
        }
      );
    },
    // 复制户号
    copyDoor: function () {
      this.$parent.$parent.copySome(
        this.orderInfo.doorNum,
        1,
        () => {
          this.copyDoorIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.$parent.copyErr();
        }
      );
    },
    // 复制金额
    copyAmount: function () {
      this.$parent.$parent.copySome(
        this.orderInfo.amount,
        2,
        () => {
          this.cptyAmountIf = true;
        },
        // eslint-disable-next-line
        (err) => {
          this.$parent.$parent.copyErr();
        }
      );
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
