export default {
    main: {
        首页: 'HomePage',
        订单生成中: 'Order generating',
        请稍候: 'Please wait...',
        安全支付: 'Secure Payment',
        订单号: 'OrderID',
        金额: 'Amount',
        支付提示: 'Tips',
        请在: 'please at ',
        秒内: ' seconds ',
        完成: 'finish ',
        支付: 'payment ',
        超时会自动: 'timeout will auto ',
        取消: 'cancel ',
        剩余时间: 'Time left',
        订单不存在: 'Order None',
        订单已失效: 'Order expired',
        订单已失效Memo: 'Please place an order again or wait for the payment to arrive !',
        订单已关闭: 'Order closed',
        订单异常: 'Order Abnormality',
        发生错误: 'An error occurred',
        订单生成失败: 'Order generation failed',
        暂无可用的订单: 'No orders available',
    }
    //   route: {
    //     dashboard: 'Dashboard',
    //     documentation: 'Documentation',
    //     guide: 'Guide',
    //     permission: 'Permission',
    //     pagePermission: 'Page Permission',
    //     rolePermission: 'Role Permission',
    //     directivePermission: 'Directive Permission',
    //     icons: 'Icons',
    //     components: 'Components',
    //     tinymce: 'Tinymce',
    //     markdown: 'Markdown',
    //     jsonEditor: 'JSON Editor',
    //     dndList: 'Dnd List',
    //     splitPane: 'SplitPane',
    //     avatarUpload: 'Avatar Upload',
    //     dropzone: 'Dropzone',
    //     sticky: 'Sticky',
    //     countTo: 'Count To',
    //     componentMixin: 'Mixin',
    //     backToTop: 'Back To Top',
    //     dragDialog: 'Drag Dialog',
    //     dragSelect: 'Drag Select',
    //     dragKanban: 'Drag Kanban',
    //     charts: 'Charts',
    //     keyboardChart: 'Keyboard Chart',
    //     lineChart: 'Line Chart',
    //     mixChart: 'Mix Chart',
    //     example: 'Example',
    //     nested: 'Nested Routes',
    //     menu1: 'Menu 1',
    //     'menu1-1': 'Menu 1-1',
    //     'menu1-2': 'Menu 1-2',
    //     'menu1-2-1': 'Menu 1-2-1',
    //     'menu1-2-2': 'Menu 1-2-2',
    //     'menu1-3': 'Menu 1-3',
    //     menu2: 'Menu 2',
    //     Table: 'Table',
    //     dynamicTable: 'Dynamic Table',
    //     dragTable: 'Drag Table',
    //     inlineEditTable: 'Inline Edit',
    //     complexTable: 'Complex Table',
    //     tab: 'Tab',
    //     form: 'Form',
    //     createArticle: 'Create Article',
    //     editArticle: 'Edit Article',
    //     articleList: 'Article List',
    //     errorPages: 'Error Pages',
    //     page401: '401',
    //     page404: '404',
    //     errorLog: 'Error Log',
    //     excel: 'Excel',
    //     exportExcel: 'Export Excel',
    //     selectExcel: 'Export Selected',
    //     mergeHeader: 'Merge Header',
    //     uploadExcel: 'Upload Excel',
    //     zip: 'Zip',
    //     pdf: 'PDF',
    //     exportZip: 'Export Zip',
    //     theme: 'Theme',
    //     clipboardDemo: 'Clipboard',
    //     i18n: 'I18n',
    //     externalLink: 'External Link',
    //     profile: 'Profile',
    //     对接信息: 'Docking information',
    //     订单管理: 'Order management',
    //     电费订单管理: 'Electricity bill order management',
    //     电费订单上传: 'Electricity bill order upload',
    //     代付订单管理: 'Payment order management',
    //     代付订单上传: 'Payment order upload',
    //     对账与统计: 'Reconciliation and statistics',
    //     订单综合统计: 'Order comprehensive statistics',
    //     订单缴费明细: 'Order payment details',
    //     授信扣款记录: 'Credit deduction record',
    //   },
    //   navbar: {
    //     dashboard: 'Dashboard',
    //     github: 'Github',
    //     logOut: 'Log Out',
    //     profile: 'Profile',
    //     theme: 'Theme',
    //     size: 'Global Size',
    //     余额: 'balance',
    //     修改密码: 'Change password',
    //     动态码: 'Dynamic code',
    //   },
    //   login: {
    //     title: 'Login Form',
    //     logIn: 'Login',
    //     username: 'Username',
    //     password: 'Password',
    //     any: 'any',
    //     thirdparty: 'Or connect with',
    //     thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !',
    //     动态码: 'abc',
    //   },
    //   documentation: {
    //     documentation: 'Documentation',
    //     github: 'Github Repository'
    //   },
    //   permission: {
    //     addRole: 'New Role',
    //     editPermission: 'Edit',
    //     roles: 'Your roles',
    //     switchRoles: 'Switch roles',
    //     tips: 'In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.',
    //     delete: 'Delete',
    //     confirm: 'Confirm',
    //     cancel: 'Cancel'
    //   },
    //   guide: {
    //     description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    //     button: 'Show Guide'
    //   },
    //   components: {
    //     documentation: 'Documentation',
    //     tinymceTips: 'Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.',
    //     dropzoneTips: 'Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.',
    //     stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    //     backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    //     backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    //     imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
    //   },
    //   table: {
    //     dynamicTips1: 'Fixed header, sorted by header order',
    //     dynamicTips2: 'Not fixed header, sorted by click order',
    //     dragTips1: 'The default order',
    //     dragTips2: 'The after dragging order',
    //     title: 'Title',
    //     importance: 'Imp',
    //     type: 'Type',
    //     remark: 'Remark',
    //     search: 'Search',
    //     add: 'Add',
    //     export: 'Export',
    //     reviewer: 'reviewer',
    //     id: 'ID',
    //     date: 'Date',
    //     author: 'Author',
    //     readings: 'Readings',
    //     status: 'Status',
    //     actions: 'Actions',
    //     edit: 'Edit',
    //     publish: 'Publish',
    //     draft: 'Draft',
    //     delete: 'Delete',
    //     cancel: 'Cancel',
    //     confirm: 'Confirm',
    //     noData: 'No data',
    //     yes: 'Yes',
    //     no: 'No',
    //     operation: 'Operation',
    //     input: 'Please input',
    //     add: 'Add',
    //     inputNumber: 'Please input number',
    //   },
    //   notice: {
    //     alreadyCancel: 'Already canceled',
    //     areYouSure: 'Are you sure???',
    //     notice: 'Notice',
    //     加载memo: 'Loading, please wait...',
    //   },
    //   example: {
    //     warning: 'Creating and editing pages cannot be cached by keep-alive because keep-alive include does not currently support caching based on routes, so it is currently cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or do not use keep-alive include to cache all pages directly. See details'
    //   },
    //   errorLog: {
    //     tips: 'Please click the bug icon in the upper right corner',
    //     description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    //     documentation: 'Document introduction'
    //   },
    //   excel: {
    //     export: 'Export',
    //     selectedExport: 'Export Selected Items',
    //     placeholder: 'Please enter the file name (default excel-list)'
    //   },
    //   zip: {
    //     export: 'Export',
    //     placeholder: 'Please enter the file name (default file)'
    //   },
    //   pdf: {
    //     tips: 'Here we use window.print() to implement the feature of downloading PDF.'
    //   },
    //   theme: {
    //     change: 'Change Theme',
    //     documentation: 'Theme documentation',
    //     tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
    //   },
    //   tagsView: {
    //     refresh: 'Refresh',
    //     close: 'Close',
    //     closeOthers: 'Close Others',
    //     closeAll: 'Close All',
    //     首页: 'Dashboard',
    //   },
    //   settings: {
    //     title: 'Page style setting',
    //     theme: 'Theme Color',
    //     tagsView: 'Open Tags-View',
    //     fixedHeader: 'Fixed Header',
    //     sidebarLogo: 'Sidebar Logo'
    //   },
    //   //核销后台
    //   wmerch: {
    //     apis: {
    //       对接编号: 'connection number',
    //       签名密钥: 'signature key',
    //       网关地址: 'gateway address',
    //       上单接口: 'order interface',
    //       查单接口: 'order query interface',
    //       撤单接口: 'order cancellation interface',
    //       异步回调IP: 'asynchronous callback IP',
    //       在线文档: 'online document',
    //       对接签名文档: 'connection signature document',
    //       话费上单接口文档: 'phone bill order interface document',
    //       电费上单接口文档: 'electricity bill order interface document',
    //       查单接口文档: 'order query interface document',
    //       撤单接口文档: 'order cancellation interface document',
    //       异步回调文档: 'asynchronous callback document',
    //       php代码例子: 'php code example',
    //       IP白名单设置: 'IP whitelist setting',
    //       是否启用: 'whether to enable',
    //       填写IP地址: 'Fill in IP address',
    //       填写IP地址Memo: 'Multiple IPs are separated by spaces or line breaks',
    //       其他设置: 'Other settings',
    //       订单超时时间: 'Order timeout',
    //       订单超时时间Memo: 'Only the timeout for manual orders through the background, and the interface order is not affected',
    //       部分支付是否回调: 'Partial payment callback',
    //       部分支付是否回调Memo1: 'Note: This function is enabled for one order, please properly handle the callback logic!!!',
    //       部分支付是否回调Memo2: 'If you don\'t receive the callback information, treat the order as a cancellation or end state',
    //       需要回调: 'Callback required',
    //       不用回调: 'No callback required',
    //       //
    //       请输入: 'Please enter',
    //       秒: 'Seconds',
    //       更新: 'Update',
    //       重置: 'Reset',
    //       启用: 'Enable',
    //       禁用: 'Disable',
    //       保存: 'Save',
    //     },
    //   },
    //   trip: {
    //     wof_order_comp: {
    //       核销单号: 'Verification order number',
    //       户号: 'Account number',
    //       油卡卡号: 'Oil card number',
    //       核销商编号: 'Distributor number',
    //       核销商名称: 'Distributor name',
    //       金额: 'Amount',
    //       地区: 'Region',
    //       系统单号: 'System order number',
    //       户号错误: 'Account number error',
    //       错误: 'Error',
    //       正常: 'Normal',
    //       授信状态: 'Credit status',
    //       授信额充足: 'Sufficient credit',
    //       授信额已预警: 'Credit has been warned',
    //       派单状态: 'Order status',
    //       开启派单: 'Start order',
    //       停止派单: 'Stop order',
    //       '创建日期-起': 'Creation date-from',
    //       '创建日期-终': 'Creation date-to',
    //       '超时日期-起': 'Timeout date-from',
    //       '超时日期-终': 'Timeout date-to',
    //       '回调日期-起': 'Callback date-from',
    //       '回调日期-终': 'Callback date-to',
    //       '结束日期-起': 'End date-from',
    //       '结束日期-终': 'End date-to',
    //       全部: 'All',
    //       待检测: 'To be tested',
    //       待使用: 'To be used',
    //       取单中: 'To be picked',
    //       待缴费: 'To be paid',
    //       部分付: 'Partially paid',
    //       已缴费: 'Paid',
    //       未缴费: 'Not paid',
    //       异常的: 'Not paid',
    //       已取消: 'Cancelled',
    //       未回调: 'Not called back',
    //       未结束: 'Not ended',
    //       已结束: 'Ended',
    //       根据条件统计: 'Statistics based on conditions',
    //       实时库存统计: 'Real-time inventory statistics',
    //       根据条件回调: 'Call back based on conditions',
    //       根据条件撤单: 'Cancel the order based on conditions',
    //       根据条件导出: 'Export based on conditions',
    //       系统单号: 'System order number',
    //       客户名称: 'Customer name',
    //       核销商编号: 'Distributor number',
    //       核销商名称: 'Distributor name',
    //       手机: 'Mobile phone',
    //       运营商: 'Operator',
    //       户号: 'Account number',
    //       类型: 'Type',
    //       地区: 'Region',
    //       油卡卡号: 'Fuel card number',
    //       代付账户: 'Payment account',
    //       代付地址: 'Payment address',
    //       金额: 'Amount',
    //       已充: 'Recharged',
    //       最低: 'Minimum',
    //       金额状态: 'Amount status',
    //       查询金额: 'Query amount',
    //       拆单: 'Split order',
    //       已拆: 'Split',
    //       未拆: 'Not split',
    //       本次应充: 'This time to be charged',
    //       拉单次数: 'Pull',
    //       缴费: 'Payment',
    //       零充值: 'Zero recharge',
    //       已全充: 'Fully charged',
    //       部分充: 'Partial charge',
    //       已超充: 'Overcharged',
    //       其他: 'Others',
    //       状态: 'Status',
    //       回调: 'Callback',
    //       已回调: 'Called back',
    //       不需要: 'Not required',
    //       未回调: 'Not called back',
    //       创建时间: 'Creation time',
    //       超时时间: 'Timeout time',
    //       回调时间: 'Callback time',
    //       结束时间: 'End time',
    //       备注: 'Remarks',
    //       错误: 'Error',
    //       户号: 'Account number',
    //       正常: 'Normal',
    //       JD黑户错误: 'JD black account error',
    //       是否改单: 'Change',
    //       撤单: 'Cancel order',
    //       强制撤单: 'Forced order cancellation',
    //       强制回调: 'Forced callback',
    //       异常恢复: 'Abnormal recovery',
    //       详细信息表头: 'Details',
    //       详情: 'Details',
    //       详细信息: 'Details info',
    //       修改时间: 'Modification time',
    //       户号金额类型: 'Account number amount type',
    //       GW公司ID: 'GW company ID',
    //       GW公司名: 'GW company name',
    //       本次已充: 'This time charged',
    //       金额变动前: 'Before amount change',
    //       回调地址: 'Callback address',
    //       上次缴费时间: 'Last payment time',
    //       上次取单时间: 'Last order pickup time',
    //       上次查单时间: 'Last order check time',
    //       异常时间: 'Abnormal time',
    //       上次使用时间: 'Last use time',
    //       检测错误数: 'Number of detection errors',
    //       查余额次数: 'Number of balance checks',
    //       绑定支付单: 'Bind payment order',
    //       查单: 'Check order',
    //       调整: 'Adjustment',
    //       订单金额: 'Order amount',
    //       已付金额: 'Paid amount',
    //       拆单应付: 'Split order payable',
    //       应付金额: 'Payable amount',
    //       本次已付: 'Paid this time',
    //       修改已付缴费: 'Modify paid payment',
    //       备注: 'Remarks',
    //       高级验证: 'Advanced verification',
    //       时间: 'Time',
    //       账户: 'Account',
    //       最小缴费: 'Minimum payment',
    //       金额: 'Amount',
    //       有效金额: 'Valid amount',
    //       立即获取最新的: 'Get the latest immediately',
    //       实时统计: 'Real-time statistics',
    //       包含无授信额的核销: 'Include write-offs without credit limit',
    //       包含停止派单的核销: 'Include write-offs of stopped orders',
    //       单: 'Order',
    //       元: 'Yuan',
    //       可用核销单: 'Available cancellation orders:',
    //       核销单总额: 'Total cancellation orders:',
    //       待缴库存: 'Inventory to be paid:',
    //       挂起库存: 'Suspended inventory:',
    //       可用库存: 'Available inventory',
    //       刷新统计: 'Refresh statistics',
    //       请输入高级验证: 'Please enter advanced verification',
    //       强制撤单notice: "Please enter the advanced password (secondary password or dynamic password)",
    //       强制撤单title: 'Note: Do not modify the verification form unless there are special circumstances; modifying the verification form will not modify the merchant order at the same time; if you need to modify the merchant order, please make up or modify the merchant order',
    //       条件撤单notice: "After cancellation, it will not be refunded, please pay attention to whether the funds are accurate",
    //       条件撤单title: 'Please enter advanced verification',
    //       核销条件撤单notice: 'It may take a long time. Please confirm again whether there are any uncancelled orders after cancellation. Are you sure? ',
    //       核销条件撤单title: 'Cancel order based on conditions',
    //       条件回调notice: 'Please enter the advanced password (secondary password or dynamic password)',
    //       条件回调title: 'Forced callback based on conditions, which may take a long time, are you sure? ',
    //       条件回调msg: 'Background is calling back one by one, please wait for a while',
    //       根据条件实时统计数据如下: 'Real-time statistical data according to conditions are as follows',
    //       补单title: 'Note: Do not modify the verification form unless there are special circumstances; modifying the verification form will not modify the merchant order at the same time; if you need to modify the merchant order, please make up or modify the merchant order',
    //       查单: 'Check order',
    //       编辑招聘者: 'Edit recruiter',
    //     },
    //     wof_order_statis_comp: {
    //       起始日期: 'Start date (default today)',
    //       结束日期: 'End date (default to now)',
    //       核销商: 'Verifier',
    //       统计日期: 'Statistical date',
    //       核销商名: 'Verifier name',
    //       单笔金额: 'Single amount',
    //       标记信息: 'Tag information',
    //       合计: 'Total',
    //       全部: 'All',
    //       统计日期: 'Statistical date',
    //       核销商名: 'Verification business name',
    //       上级核销: 'Superior verification',
    //       单笔金额: 'Single amount',
    //       标记信息: 'Tag information',
    //       上单数量: 'Number of orders',
    //       成功笔数: 'Number of successful transactions',
    //       缴费总额: 'Total payment amount',
    //       重新统计这天: 'Recalculate this day',
    //       重新统计这天notice: 'When the amount of data is too large, it may take some time. Are you sure you want to execute it? ? ? ',
    //       汇总: 'Summary',
    //     },
    //     wof_order_detail_comp: {
    //       下单开始日期: 'Order start date',
    //       下单结束日期: 'Order end date',
    //       核销单号: 'Verification order number',
    //       户号: 'Account number',
    //       核销商: 'Verification dealer',
    //       下单金额: 'Order amount',
    //       缴费状态: 'Payment status',
    //       已缴费: 'Paid',
    //       未缴费: 'Unpaid',
    //       标记: 'Mark',
    //       正常: 'Normal',
    //       补单: 'Make up order',
    //       调整: 'Adjustment',
    //       系统单号: 'System order number',
    //       根据条件导出: 'Export according to conditions',
    //       下单时间: 'Order time',
    //       系统单号: 'System order number',
    //       核销商信息: 'Redeemer information',
    //       缴费金额: 'Payment amount',
    //       缴费时间: 'Payment time',
    //       备注: 'Remarks',
    //     },
    //     wof_credit_logs_comp: {
    //       开始日期: 'Start date',
    //       结束日期: 'End date',
    //       核销商编号: 'Verifier number',
    //       类型: 'Type',
    //       管理员增加: 'Admin increase',
    //       代理转移增加: 'Agent transfer increase',
    //       订单缴费扣除: 'Order payment deduction',
    //       管理员扣除: 'Admin deduction',
    //       代理转移扣除: 'Agent transfer deduction',
    //       返还授信: 'Return credit',
    //       核销单号: 'Cancellation form number',
    //       时间: 'Time',
    //       核销编号: 'Cancellation number',
    //       核销名称: 'Cancellation name',
    //       变更前: 'Before change',
    //       变更后: 'After change',
    //       变更额: 'Change amount',
    //       备注: 'Remarks',
    //       核销单号: 'Verification order number',
    //       系统: 'System',
    //       无: 'None',
    //       辅助单号: 'Account number/mobile phone number',
    //     },
    //   },
    //   order: {
    //     wm_upload_lfel: {
    //       核销单号: 'Verification order number',
    //       全部: 'All',
    //       正确的: 'Correct',
    //       错误的: 'Incorrect',
    //       选择文件上传: 'Select file to upload',
    //       单个录入: 'Single entry',
    //       确定提交: 'Confirm submission',
    //       全部清理: 'Clear all',
    //       下载模板: 'Download template',
    //       行号: 'Line number',
    //       核销单号: 'Verification order number',
    //       缴费户号: 'Payment account number',
    //       缴费金额: 'Payment amount',
    //       地区: 'Region',
    //       城市: 'City',
    //       备注: 'Remarks',
    //       正常: 'Normal',
    //       订单编号: 'Order No.',
    //       订单编号memo: 'Optional, but must be unique',
    //       缴费户号: 'Account No.:',
    //       缴费金额: 'Payment Amount:',
    //       省: 'Province:',
    //       市: 'City:',
    //     },
    //     wm_upload_daifu: {
    //       您的单号: 'Your Order No.',
    //       全部: 'All',
    //       正确的: 'Correct',
    //       错误的: 'Incorrect',
    //       上码: 'Code',
    //       确定提交: 'Confirm Submit',
    //       全部清理: 'Clear All',
    //       行号: 'Line No.',
    //       账号: 'Account No.',
    //       金额: 'Amount',
    //       链接: 'Link',
    //       备注: 'Remarks',
    //       正常: 'Normal',
    //       账号memo: 'Account: You can fill in the store name or product code name, only for easy query',
    //       金额memo: 'Amount: must be greater than 0, all links below use this amount',
    //       链接memo: 'Link: one per line, no line break in the middle',
    //     },
    //   },
}
